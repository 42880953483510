const numberFormat = (number:number) => {
        return (number<10 ? '0'+ number : number); 
}

export const getDateTime = () =>{
    const today = new Date();
    const date = `${today.getFullYear()}-${numberFormat(today.getMonth()+1)}-${numberFormat(today.getDate())}`;
    const time = `${numberFormat(today.getHours())}:${numberFormat(today.getMinutes())}:${numberFormat(today.getSeconds())}.${today.getMilliseconds()}`;
    const dateTime = `${date} ${time}`;
    return dateTime;
}

export const handleTime = (time:string) => {
    const hours = time.substring(11, 13);
    const minutes = time.substring(14, 16);
    return `${hours}:${minutes}`;
}

export const handleDate = (time:string) => {
    const years = time.substring(0, 4);
    const months = time.substring(5, 7);
    const days = time.substring(8, 10);
    return `${years}-${months}-${days}`;
}

export const getYear = () => {
    const today = new Date();
    return today.getFullYear();
}

export const getMonth = () => {
    const today = new Date();
    return numberFormat(today.getMonth()+1);
}