import axios from "axios"
import { useEffect, useState } from "react";
import { WorkspaceInfo } from "../types/WorkspaceInfo.type";
import { useNavigate } from "react-router-dom";
import { setUserList } from "../store/userListSlice";
import { useChatDispatch, useChatSelector } from "../store";
import { setWorkspaceList } from "../store/workspaceListSlice";
import { setWorkspace } from "../store/workspaceSlice";
import {ReactComponent as LogoImg} from '../assets/logo.svg';
import auth from '../css/auth.module.scss';
import { setChannelList } from "../store/channelListSlice";
import { persistor } from "../store";
import { apiUrl } from "../apis/axiosHttp";

export const Workspace = ()=>{
    const workspaceWrap = {
        gap: '36px',
        margin: 'calc((100vh - 438px)/2) auto'
    }
    const user = useChatSelector((state:any) => state.user);
    const [workspaceChk, setWorkspaceChk] = useState<boolean>(false);
    const dispatch = useChatDispatch();
    const workspaceList = useChatSelector((state:any)=>state.workspaceList.list);
    const token = localStorage.getItem('token') || '';
    const navigate = useNavigate();
     const getWorkspaces = async()=>{
        try{
            
            const res = await axios.post(`${apiUrl}/workspace-infos`, user, {
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    "Authorization" : `Bearer ${token}`
                }
             });
             if(res.data){
                dispatch(setWorkspaceList(res.data));
             }
             if(!res.data.length){
                setWorkspaceChk(true);
             }
        }catch(e){
            console.error(e);
        }
         
     }
     const goMain = async (workspace:any)=>{
        const req = {
            usiNum : user.usiNum,
            woiNum : workspace.woiNum
        }
        try{
            const resUsers = await axios.post(`${apiUrl}/user-infos`,workspace,{
                headers: {
                    'Content-Type' : 'application/json;charset=UTF-8',
                    "Authorization" : `Bearer ${token}`
                }
            });
            const resChannels = await axios.post(`${apiUrl}/channel-infos`, req, {
                headers : {
                    'Content-Type' : 'application/json;charset=UTF-8',
                    'Authorization' : `Bearer ${token}`
                }
            })
            if(resUsers.data && resChannels.data){
                const userList = resUsers.data;
                const channelList = resChannels.data;
                dispatch(setUserList(userList));
                dispatch(setWorkspace(workspace));
                dispatch(setChannelList(channelList));
                navigate('/main');
            }
        }catch(e){
            console.error(e);
        }
     }

     const logoutProcess = async() => {
        try{
            const res = await axios.post(`${apiUrl}/logout`, {}, {
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    'Authorization' : `Bearer ${token}`
                }
            });
            persistor.purge();
            navigate('/');
        }catch(e){
            console.error(e);
        }
        
     }
     useEffect(()=>{
        getWorkspaces();
     },[]);
     useEffect(()=>{
        if(!workspaceList?.length){
            setWorkspaceChk(true);
        }
        if(workspaceList?.length){
            setWorkspaceChk(false);
        }
     },[workspaceList])
    return (
        <div className={auth.wrap} style={workspaceWrap}>
            <LogoImg className={auth.logo}/>
            {workspaceChk ? (<p>워크스페이스가 없습니다.</p>)
            : (<p>워크스페이스를 선택해주세요</p>)}
            <div className={auth.bxwrap}>
                {
            workspaceList&&workspaceList.map((workspace:WorkspaceInfo, idx:number)=>(
                <div className={auth.box} tabIndex={1} key={idx}onClick={()=>{goMain(workspace);}}>
                    <p>{workspace.woiName}</p>
                    <span>{workspace.woiTitle}</span>
                </div>
                ))
                }
            </div>
            <div className={auth.bwrap}>
                <button className="btn-p" onClick={()=>{navigate('/add-workspace')}}>워크스페이스 생성</button>
                <button className="btn-g" onClick={logoutProcess}>이전으로</button>
            </div>
        </div>
    )
}