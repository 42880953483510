import axios from "axios";
import { useState } from "react";
import { UserInfo } from "../types/UserInfo.type";
import { useNavigate } from "react-router-dom";
import { useChatDispatch } from "../store";
import { setUser } from "../store/userSlice";
import {ReactComponent as LogoImg} from '../assets/logo.svg';
import auth from '../css/auth.module.scss';
import { apiUrl } from "../apis/axiosHttp";

export const Login = ()=>{
    const loginWrap = {
        gap: '36px',
        margin: 'calc((100vh - 384px)/2) auto'
    }
    const dispatch = useChatDispatch();
    const [userInfo, setUserInfo] = useState<UserInfo>({});
    const [inputChk, setInputChk] = useState<boolean>(false);
    const [userChk, setUserChk] = useState<boolean>(false);
    const navigate = useNavigate();
    const getUsiEmail = (evt:any)=>{
        setUserChk(false);
        setInputChk(false);
        setUserInfo({
            ...userInfo,
            [evt.target.id] : evt.target.value
        });
    }
    const getUser = async()=>{
        if(!userInfo.usiEmail || !userInfo.usiPwd){
            setInputChk(true);
            return;
        }
        try{
            const res = await axios.post(`${apiUrl}/login`, userInfo, {
                headers:{
                    'Content-Type' : 'application/json;charset=UTF-8'
                }
            });
            if(res.data){
                dispatch(setUser(res.data));
                localStorage.setItem('usiNum', res.data.usiNum);
                localStorage.setItem('token', res.data.token);
                setUserChk(false);
                setInputChk(false);
                navigate('/workspace');
            }                
        }catch(e){
            setUserChk(true);
        }    
    }
    return (
        <div className={auth.wrap} style={loginWrap}>
            {/*<img src={ logoImg } alt="" className={auth.logo} />*/}
            <LogoImg className={auth.logo}/>
            <div className={auth.iwrap}>
                <div className={auth.input}>
                    <label htmlFor="usiEmail">이메일</label>
                    <input type="email" id="usiEmail" placeholder="이메일을 입력하세요." onChange={getUsiEmail}></input>
                </div>
                <div className={auth.input}>
                    <label htmlFor="usiPwd">비밀번호</label>
                    <input type="password" id="usiPwd" placeholder="비밀번호를 입력하세요." onChange={getUsiEmail}></input>
                    {inputChk&&<span className={auth.errmsg}>이메일 및 비밀번호를 확인해주세요.</span>}
                    {userChk&&<span className={auth.errmsg}>회원정보가 없습니다.</span>}
                </div>
            </div>
            <div className={auth.bwrap}>
                <button className="btn-p" onClick={getUser}>로그인</button>
                <button className="btn-g" onClick={()=>{navigate('/join')}}>회원가입</button>
            </div>
        </div>
    );
}