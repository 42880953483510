import axios from 'axios';
import nav from '../css/nav.module.scss';
import { useChatDispatch, useChatSelector } from '../store';
import { initChatUser, setChatUser } from '../store/chatUserSlice';
import { UserInfo } from '../types/UserInfo.type';
import { setUserList } from '../store/userListSlice';
import { openModal } from '../store/modalSlice';
import { ChannelInfo } from '../types/ChannelInfo.type';
import { initChannel, setChannel } from '../store/channelSlice';
import { initMemberList, setMemberList } from '../store/memberListSlice';
import { setChannelList } from '../store/channelListSlice';
import { initEmojiList } from '../store/emojiListSlice';
import defaultImg from '../assets/img_default.png';
import { setProfileUser } from '../store/profileUserSlice';
import onlineImg from '../assets/ellipse_yellow.png'
import offlineImg from '../assets/ellipse_gray.png'
import enabledImg from '../assets/ellipse_green.png'
import disabledImg from '../assets/ellipse_red.png'
import { apiUrl } from '../apis/axiosHttp';

export const Nav = ()=>{
    const token = localStorage.getItem('token');
    const userList = useChatSelector((state:any)=>state.userList.list);
    const workspace = useChatSelector((state:any)=>state.workspace);
    const loginUser = useChatSelector((state:any)=>state.user);
    const channelList = useChatSelector((state:any)=>state.channelList.list);
    const dispatch = useChatDispatch();
    const enterChat = (chatUser:any)=>{
        updateReceiveTime(chatUser);
        const tmpUserList = JSON.parse(localStorage.getItem('userList') ||'[]');
        for(const tmpUser of tmpUserList){
            if(chatUser.usiNum === tmpUser.usiNum){
                tmpUser.unReadCnt = 0;
            }
        }
        dispatch(initChannel());
        dispatch(initMemberList());
        dispatch(setChatUser(chatUser));
        dispatch(setUserList(tmpUserList));
    }
    const updateReceiveTime = async (obj:any) => {
        if(obj.chiNum && !obj.usiNum){
            const req = {
                msiReceiveUsiNum : 0,
                chiNum : obj.chiNum
            }
            try{
                await axios.put(`${apiUrl}/update-channel-messages`, req, {
                    headers : {
                        Authorization : `Bearer ${token}`
                    }
                })
            }catch(e){
                console.error(e);
            }
        }
        if(obj.usiNum && !obj.chiNum){
            const req = {
                msiSenderUsiNum : obj.usiNum,
                msiReceiveUsiNum : loginUser.usiNum
            }
            try{
                await axios.put(`${apiUrl}/update-messages`, req, {
                    headers : {
                        Authorization : `Bearer ${token}`
                    }
                })
            }catch(e){
                console.error(e);
            }
        }
        
        
    }

    const enterChannel = (channel:any) => {
        updateReceiveTime(channel);
        const tmpChannelList = JSON.parse(localStorage.getItem('channelList') || '[]');
        for(const tmpChannel of tmpChannelList){
            if(channel.chiNum === tmpChannel.chiNum){
                tmpChannel.unReadCnt = 0;
            }
        }
        dispatch(initChatUser());
        dispatch(initEmojiList());
        dispatch(setChannel(channel));
        dispatch(setChannelList(tmpChannelList));
        getMember(channel);
    }

    const getMember = async (channel:any) => {
        const req = {
            chiNum : channel.chiNum
        };
        
        try{
            const res = await axios.post(`${apiUrl}/member-user-infos`,req,{
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    'Authorization' : `Bearer ${token}`
                }
            });
            const memberList:any = {
                chiNum : channel.chiNum,
                list : res.data
            }
            if(res.data){
                dispatch(setMemberList(memberList));
            }
        }catch(e){
            console.error(e);
        }

    }
    const handleOpenModal = (type:any) => {
        dispatch(openModal(type));
    }
    
    const handleUserStat = (usiStat:any) => {
        if(usiStat === 'ONLINE'){
            return <img className={nav.statImg} src={onlineImg}/>
        }
        if(usiStat === 'ENABLED'){
            return <img className={nav.statImg} src={enabledImg}/>;
        }
        if(usiStat === 'DISABLED'){
            return <img className={nav.statImg} src={disabledImg}/>;
        }
        if(usiStat === 'OFFLINE'){
            return <img className={nav.statImg} src={offlineImg}/>;
        }
    }
    return (
        <div className={nav.wrap}>
            <div className={nav.top}>
                <p>{workspace.woiName}</p>
                <div className={nav.channel}>
                    <div className={nav.tit}>
                        <p className={nav.toggle}></p>
                        <p>채널</p>
                    </div>
                    <div className={nav.cont}>
                        {
                            channelList.length ? channelList.map((channel:ChannelInfo, idx:number)=>(
                            <div key={idx} className={nav.row} onClick={()=>{enterChannel(channel)}}>
                                {
                                    channel.chiType==='PUBLIC' ? (
                                        <p className={nav.public}>{channel.chiName}</p>
                                    ) : (
                                        <p className={nav.private}>{channel.chiName}</p>
                                    )
                                }
                                {channel.unReadCnt ? <span className={nav.cnt}>{channel.unReadCnt}</span> : ''}
                            </div>
                            ))
                            : ''
                        }
                    </div>
                    <p className={nav.add} onClick={()=>{handleOpenModal('channel')}}>채널 추가</p>
                </div>
                <div className={nav.dm}>
                    <div className={nav.tit}>
                        <p className={nav.toggle}></p>
                        <p>다이렉트 메시지</p>
                    </div>
                    <div className={nav.cont}>
                        {
                        userList.map((user:UserInfo, idx:number)=>(
                            <div key={idx} className={nav.row} onClick={()=>{enterChat(user)}}>
                            <p>{(user.usiNum === loginUser.usiNum) ? `${user.usiName} (나)` : user.usiName }</p>
                            <div className={nav.statImgWrap}>{handleUserStat(user.usiStat)}</div>
                            {user.unReadCnt ? <span className={nav.cnt}>{user.unReadCnt}</span> : ''}
                            </div>
                        ))
                        }
                    </div>
                    <p className={nav.add} onClick={()=>{handleOpenModal('user')}}>사용자 추가</p>
                </div>
            </div>
            <div className={nav.bottom}>
                <div className={nav.bottom_link}>
                    {/* a태그 link 태그로 바꿀 예정 */}
                    {/* <p>파일 목록</p>
                    <p>활동 기록</p> */}
                </div>
                <div className={nav.bottom_profile}>
                    <div className={nav.profileWrap}>
                        <img className={nav.profilePreview} src={loginUser.usiImgUrl ? loginUser.usiImgUrl : defaultImg}/>
                    </div>
                        <p onClick={()=>{
                            dispatch(setProfileUser(loginUser));
                            handleOpenModal('profile');
                            }}>{loginUser.usiName}</p>
                        <span className={nav.userStatImgWrap}>{handleUserStat(loginUser.usiStat)}</span>
                </div>
            </div>
        </div>
    );
}