import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

interface UserListState {
    list: any[];
}

const initialState: UserListState = {
    list: [],
};

const userListSlice = createSlice({
    name: 'userList',
    initialState,
    reducers: {
        setUserList: (state, action: PayloadAction<any[]>) => {
            if (action.payload) {
                localStorage.setItem('userList', JSON.stringify(action.payload));
                state.list = action.payload;
            }
        },
        initUserList: (state) => {
            state.list = [];
        }
    },
    extraReducers: (builder) => {
        builder.addCase(PURGE, () => {
            return initialState
        });
    }
});

export const { setUserList, initUserList } = userListSlice.actions;
export default userListSlice.reducer;