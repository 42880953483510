import content from '../css/content.module.scss';
import { useChatDispatch, useChatSelector } from '../store';
import { MessageList } from './MessageList';
import { AddUserModal } from './AddUserModal';
import { AddChannelModal } from './AddChannelModal';
import { Editor } from './Editor';
import { EditMessageModal } from './EditMessageModal';
import { DeleteMessageModal } from './DeleteMessageModal';
import axios from 'axios';
import { setEmojiList } from '../store/emojiListSlice';
import { setFileList } from '../store/fileListSlice';
import { initMessageList, setMessageList } from '../store/messageListSlice';
import { useState } from 'react';
import { ProfileModal } from './ProfileModal';
import { openModal } from '../store/modalSlice';
import { setProfileUser } from '../store/profileUserSlice';
import { apiUrl } from '../apis/axiosHttp';

export const Content = ()=>{
    const chatUser = useChatSelector((state:any)=>state.chatUser);
    const channel = useChatSelector((state:any)=>state.channel);
    const workspace = useChatSelector((state:any)=>state.workspace);
    const user = useChatSelector((state:any)=>state.user);
    const [msiMessage, setMsiMessage] = useState<string>('');
    const [msgLoading, setMsgLoading] = useState<boolean>(false);
    const token = localStorage.getItem('token');
    const dispatch = useChatDispatch();
             
    const searchMessages = async () => {
        dispatch(initMessageList());
        setMsgLoading(true);
        const req = {
            msiSenderUsiNum : user.usiNum,
            msiReceiveUsiNum : chatUser.usiNum,
            msiMessage : msiMessage,
            woiNum : workspace.woiNum,
        }
        try{
            const res = await axios.post(`${apiUrl}/message-infos`, req, {
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    "Authorization" : `Bearer ${token}`
                }
            });
            const message:any = {
                msiSenderUsiNum : user.usiNum,
                msiReceiveUsiNum : chatUser.usiNum,
                chiNum : 0,
                list : res.data.messageList
            }
            if(res.data.emojiList){
                dispatch(setEmojiList(res.data.emojiList));
            }
            if(res.data.fileList){
                dispatch(setFileList(res.data.fileList));
            }
            if(res.data.messageList){
                dispatch(setMessageList(message));
            }
            if(!res.data.messageList){
                dispatch(initMessageList());
            }
        }catch(e){
            console.error(e);
        }
        setMsgLoading(false);
    }

    const handlerChangeInput = (evt:any) => {
        setMsiMessage(evt.target.value);
    }

    const handlekeyUp = (evt:any) => {
        if(evt.key === 'Enter'){
            searchMessages();
        }
      }
      const handleOpenModal = (type:any) => {
        dispatch(setProfileUser(chatUser));
        dispatch(openModal(type));
    }
    return (
        <div className={content.wrap}>
            <div className={content.df}>
                <input type="text" id="msiMessage" onKeyUp={handlekeyUp} onChange={(evt)=>{handlerChangeInput(evt)}}/>
                <button className='btn-p' onClick={searchMessages}>검색</button>
            </div>
            <h4 className={content.title} onClick={()=>{handleOpenModal('profile')}}>
                {chatUser.usiNum ? ((user.usiNum===chatUser.usiNum) ? `${chatUser.usiName} (나)` : (chatUser.usiName)) : ((channel.chiNum ? channel.chiName : `${workspace.woiName} 공지사항`)) }</h4>
            {
            msgLoading && 
                <div className="spinnerWrap">
                    <div className="spinner"></div>
                </div>
            }
            <AddUserModal></AddUserModal>
            <AddChannelModal></AddChannelModal>
            <EditMessageModal></EditMessageModal>
            <ProfileModal></ProfileModal>
            <DeleteMessageModal></DeleteMessageModal>
            <MessageList></MessageList>
            <Editor></Editor>
        </div>
    );
}