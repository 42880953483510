import Modal from 'react-modal';
import { useChatDispatch, useChatSelector } from '../store';
import { closeModal } from '../store/modalSlice';
import modal from '../css/modal.module.scss';
import { useState } from 'react';
import axios from 'axios';
import { UserInfo } from '../types/UserInfo.type';
import { setChannelList } from '../store/channelListSlice';
import { apiUrl } from '../apis/axiosHttp';

export const AddChannelModal = ()=>{
    const token = localStorage.getItem('token');
    const user = useChatSelector((state:any)=>state.user);
    const [error, setError] = useState<string>('');
    const dispatch = useChatDispatch();
    const isOpen = useChatSelector((state:any)=>state.modal.channel.isOpen);
    const userList = useChatSelector((state:any)=>state.userList.list);
    const workspace = useChatSelector((state:any)=>state.workspace);
    const [chiName, setChiName] = useState<string>('');
    const [usiNums, setUsiNums] = useState<number[]>([]);
    const [chiType, setChiType] = useState<string>('');

    const handleCloseModal = () =>{
        setError('');
        dispatch(closeModal('channel'));
    }

    const handleChangeEvt = (evt:any) => {
        setError('');
        setChiName(evt.target.value);
    }

    const handleCheckEvt = async (usiNum:number) => {
        setError('');
        const isChecked = usiNums.includes(usiNum);

        if(isChecked){
            setUsiNums((prev)=>prev.filter((e)=> e !== usiNum));
        }
        if(!isChecked){
            setUsiNums((prev)=>[...prev, usiNum]);
        }
    }

    const handleRadioEvt = (evt:any) => {
        setError('');
        setChiType(evt.target.value.toUpperCase());
    }

    const getChannelList = async(init:boolean) => {
        const req = {
            usiNum : user.usiNum,
            woiNum  : workspace.woiNum
        }
            try{
                const res = await axios.post(`${apiUrl}/channel-infos`, req, {
                    headers : {
                        "Content-Type" : 'application/json;charset=UTF-8',
                        Authorization : `Bearer ${token}`
                    }
                });
                if(res.data.length){
                    dispatch(setChannelList(res.data));
                }
            }catch(e){
                console.error(e);
            }
    }

    const addChennel = async () => {
        const channelObj = {
            woiNum : workspace.woiNum,
            chiName : chiName,
            chiType : chiType,
            usiNums : usiNums
        }
            if(!chiName){
                setError('채널명을 입력해주세요.')
                return;
            }
            if(!chiType){
                setError('채널타입을 선택해주세요.')
                return;
            }
            if(usiNums.length<2){
                setError('최소 2명의 멤버를 선택해주세요.');
                return;
            }

            try{
                const res = await axios.post(`${apiUrl}/insert-channel`, channelObj, {
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    Authorization : `Bearer ${token}`
                }
            });
            if(res.data){
                //모달 닫기
                dispatch(closeModal('channel'));
                getChannelList(res.data);
                setError('');
                setChiName('');
                setChiType('');
                setUsiNums([]);
            }
            }catch(e){
                console.error(e);
            }
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={handleCloseModal} className={modal.modalContent} overlayClassName={modal.modalOverlay}>
                    <div className={modal.modalTop} >
                        <h2 className={modal.modalTitle}>채널 추가</h2>
                    </div>
                    <div className={modal.modalBody}>
                        <div className={modal.modalLabel}>
                            <label htmlFor="">채널명</label><span className='fc-r'>*</span>
                        </div>
                        <div className={modal.modalInputGroup}>
                            <input className={modal.modalInput} type="text" id="chiName" placeholder="채널명을 입력하세요." onChange={(evt)=>{handleChangeEvt(evt)}}></input>
                        </div>
                        <div className={modal.modalLabel}>
                            <label htmlFor="">채널타입</label><span className='fc-r'>*</span>
                        </div>
                        <div className={modal.modalInputGroup}>
                            <label className={modal.modalLabel} htmlFor="public">공개</label>
                            <input type='radio' id='public' value='public' name='chiType' onChange={(evt)=>{handleRadioEvt(evt)}}></input>
                            <label className={modal.modalLabel} htmlFor="private">비공개</label>
                            <input type='radio' id='private' value='private' name='chiType' onChange={(evt)=>{handleRadioEvt(evt)}}></input>
                        </div>
                        <div className={modal.modalLabel}>
                            <label htmlFor="">채널멤버</label><span className='fc-r'>*</span>
                        </div>
                            {
                            userList.map((user:UserInfo, idx:number)=>(
                                <div key={idx} className={modal.modalInputGroup}>
                                    <label className={modal.modalLabel} htmlFor=''>
                                    <input type='checkbox' onChange={()=>{handleCheckEvt(user.usiNum || 0)}}></input>
                                    <span>{user.usiName}</span>
                                    </label>
                                </div>
                            ))}
                        {error&&<span className={modal.modalError}>{error}</span>}
                    </div>
                    <div className={modal.modalButtonGroup}>
                        <button className="btn-p" onClick={addChennel}>추가</button>
                        <button className="btn-g" onClick={handleCloseModal}>닫기</button>
                    </div>
                </Modal>
    );
}