import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    msiSenderUsiNum : 0,
    msiReceiveUsiNum : 0,
    chiNum : 0,
    list : []
}
const messageListSlice = createSlice({
    name : 'messageList',
    initialState: initialState,
    reducers:{
        setMessageList : (state:any, action:any)=>{
            localStorage.setItem('messageList', JSON.stringify(action.payload));
            state.msiSenderUsiNum = action.payload.msiSenderUsiNum;
            state.msiReceiveUsiNum = action.payload.msiReceiveUsiNum;
            state.chiNum = action.payload.chiNum;
            state.list = action.payload.list;
        },
        initMessageList:(state:any)=>{
            localStorage.removeItem('messageList');
            state.msiSenderUsiNum = 0;
            state.msiReceiveUsiNum = 0;
            state.chiNum = 0;
            state.list = [];
        }
    },
    extraReducers : (builder) => {
        builder.addCase(PURGE, ()=> initialState)
    }
});

export const {setMessageList, initMessageList} = messageListSlice.actions;
export default messageListSlice.reducer;
