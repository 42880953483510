import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserInfo } from "../types/UserInfo.type";
import {ReactComponent as LogoImg} from '../assets/logo.svg';
import defaultImg from '../assets/img_default.png';
import auth from '../css/auth.module.scss';
import axios from "axios";
import { getMonth, getYear } from "../service/DateService";
import setUUID from 'uuid-random'
import { storage } from "../apis/firebase";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { apiUrl } from "../apis/axiosHttp";

export const Join = () => {

    const joinWrap = {
        gap: '16px',
        margin: 'calc((100vh - 736px)/2) auto'
    }

    const navigate = useNavigate();
    const imgRef = useRef<HTMLInputElement>(null);
    const [imageFile, setImageFile] = useState<any>(null);
    const [joinUser, setJoinUser] = useState<UserInfo>({});
    const [secondPwd, setSecondPwd] = useState<string>('');
    const [error, setError] = useState<{[key:string]:string}>({});
    const [correctMsg, setCorrectMsg] = useState('');
    const [chkClick, setChkClick] = useState<boolean>(false);
    const [emailChk, setEmailChk] = useState<boolean>(false);
    const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);
    const [imgFileChk, setImgFileChk] = useState<string>('');
    
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const pwdChk = ()=> {
        const newErrors = { ...error };
        if(!joinUser.usiPwd){
            newErrors['usiPwd'] = '비밀번호를 입력해주세요.'
        }
        if(joinUser.usiPwd && joinUser.usiPwd !== secondPwd){
            newErrors['secondPwd'] = '비밀번호가 일치하지 않습니다.'
        }
        if(!secondPwd){
            newErrors['secondPwd'] = '비밀번호를 재입력해주세요.'
        }
        if(joinUser.usiPwd && secondPwd && joinUser.usiPwd === secondPwd){
            setError({});
            setCorrectMsg('일치합니다.');
            setChkClick(true);
            return;
        }
        setError(newErrors);
    }
    const getUserInfo = async (evt:any) =>{

        setJoinUser({
            ...joinUser,
            [evt.target.id] : evt.target.value
        });
        if(!emailRegEx.test(joinUser.usiEmail || '')){
            setEmailChk(false);
        }
        if(emailRegEx.test(joinUser.usiEmail || '')){
            setEmailChk(true);
        }
        setChkClick(false);
        setError({});
        setCorrectMsg('');
    }
    
    const emailCheck = async ()=>{
        if(emailChk){
            try{
                const newErrors = { ...error };
                const res = await axios.post(`${apiUrl}/email-chk`, joinUser,{
                    headers : {
                        "Content-Type" : 'application/json;charset=UTF-8'
                    }
                });
                if(res.data){
                    newErrors['usiEmail'] = '중복된 이메일입니다.';
                    setError(newErrors);
                    setEmailChk(false);
                    return;
                }
            }catch(e){
                console.error(e);
            }
            
        }
    }
    const getSecondPwd = (evt:any) => {
        setSecondPwd(evt.target.value);
        setError({});
        setCorrectMsg('');
    }
    const joinProcess = async ()=>{
        const newErrors = { ...error };
        if(!emailRegEx.test(joinUser.usiEmail || '')){
            newErrors['usiEmail'] = '잘못된 이메일 형식입니다.'
        }
        if(!joinUser.usiEmail){
            newErrors['usiEmail'] = '이메일을 입력해주세요.'
        }
        if(!joinUser.usiName){
            newErrors['usiName'] = '이름을 입력해주세요.'
        }
        if(!joinUser.usiPwd){
            newErrors['usiPwd'] = '비밀번호를 입력해주세요.'
        }
        if(!chkClick){
            newErrors['secondPwd'] = '비밀번호 확인을 해주세요.'
        }
        if(!secondPwd){
            newErrors['secondPwd'] = '비밀번호를 재입력해주세요.'
        }
        setError(newErrors);
        if(!newErrors || Object.keys(newErrors).length === 0){
            // 파일업로드 파이어베이스로 변경
            if(imageFile){
                // 파이어베이스 업로드 로직 처리
                const userFile = await uploadFile();
                const joinFileUser = {
                    ...joinUser, 
                    usiImgName : userFile?.usiImgName,
                    usiImgUuid : userFile?.usiImgUuid,
                    usiImgUrl : userFile?.usiImgUrl,
                    usiImgPath : userFile?.usiImgPath
                }
                join(joinFileUser);
            }
            if(!imageFile){
                join(joinUser);
            }
        }
    }

    const uploadFile = async () => {
        const year = getYear();
        const month = getMonth();
        const uploadPath = `/${year}/${month}`;
        const UUID = setUUID();
        const uploadUrl = `upload/imgs${uploadPath}/${UUID}_${imageFile.name}`;
        const storageRef = ref(storage, uploadUrl);
        
        try{
            await uploadBytesResumable(storageRef, imageFile);
  
            const downloadUrl = await getDownloadURL(storageRef);
            
            const tmpUserFile:UserInfo = {
                usiImgName : imageFile.name,
                usiImgUuid : UUID,
                usiImgUrl : downloadUrl,
                usiImgPath : uploadPath
            }
            return tmpUserFile;
        }catch(e){
            console.error(e);
        }
    }
    const join = async (req:UserInfo) => {
        try{
            const res = await axios.post(`${apiUrl}/join`, req,{
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8'
                }
            });
            if(res.status===200){
                alert('회원가입 완료되었습니다. 로그인 페이지로 이동합니다.');
                navigate('/');
                setError({});
            }
            return;
        }
        catch(e){
            console.error(e);
        }
    }
    const onChangeImage = (e:React.ChangeEvent<HTMLInputElement>)=>{
            const reader = new FileReader();
            const file = e.target.files && e.target.files[0];
            if(file?.type.startsWith('image/')){
                reader.onloadend = () => {
                    setImageUrl(reader.result);
                }
                reader.readAsDataURL(file);
                setImageFile(file);
                setImgFileChk('');
                return;
            }
            if(!file?.type.startsWith('image/')){
                // 이미지파일이 아닌경우
                setImgFileChk('이미지 형식만 가능합니다.');
                return;
            }
    }
    useEffect(()=>{
        if(emailChk){
            emailCheck();
        }
    },[emailChk]);
    return (
        <div className={auth.wrap} style={joinWrap}>
            <LogoImg className={auth.logo}/>
            <div className={auth.iwrap}>
                <div className={auth.input}>
                    <label htmlFor="usiEmail">이메일 <span className="fc-r">*</span></label>
                    <input type="email" id="usiEmail" placeholder="이메일을 입력하세요." onChange={getUserInfo}></input>
                    {error['usiEmail']&&<span className={auth.errmsg}>{error['usiEmail']}</span>}
                </div>
                <div className={auth.input}>
                    <label htmlFor="usiName">이름 <span className="fc-r">*</span></label>
                    <input type="text" id="usiName" placeholder="이름을 입력하세요." onChange={getUserInfo}></input>
                    {error['usiName']&&<span className={auth.errmsg}>{error['usiName']}</span>}
                </div>
                <div className={auth.input}>
                    <label htmlFor="usiPwd">비밀번호 <span className="fc-r">*</span></label>
                    <input type="password" id="usiPwd" placeholder="비밀번호를 입력하세요." onChange={getUserInfo}></input>
                    {error['usiPwd']&&<span className={auth.errmsg}>{error['usiPwd']}</span>}
                </div>
                <div className={auth.input}>
                    <label htmlFor="secondPwd">비밀번호확인 <span className="fc-r">*</span></label>
                    <input type="password" id="secondPwd" placeholder="비밀번호를 다시 입력하세요." onChange={getSecondPwd}></input>
                    <button className="btn-g" onClick={pwdChk}>확인</button>
                    {error['secondPwd']&&<span className={auth.errmsg}>{error['secondPwd']}</span>}
                    {correctMsg&&<span className={auth.errmsg}>{correctMsg}</span>}
                </div>
                <div className={auth.input}>
                    <label>프로필 사진</label>
                    <div className={auth.img}>
                        {imageUrl ? (<img src={ imageUrl.toString() } alt="프로필 사진" />
                        ) : (
                            <img src={ defaultImg } alt="프로필 사진" />
                        )}
                    </div>
                    <input type="file" id="usiProfile" onClick={()=>{setImgFileChk('')}} onChange={onChangeImage} ref={imgRef}></input>
                    <label htmlFor="usiProfile" className="btn-g">파일선택</label>
                    {imgFileChk&&<span className={auth.errmsg}>{imgFileChk}</span>}
                </div>
            </div>
            <div className={auth.bwrap}>
                <button className="btn-p" onClick={joinProcess}>회원가입</button>
                <button className="btn-g" onClick={()=>{navigate('/')}}>이전으로</button>
            </div>
        </div>
    );
}