import { combineReducers, configureStore } from "@reduxjs/toolkit"
import userReducer from './userSlice'
import storage from "redux-persist/lib/storage"
import { persistReducer, persistStore } from "redux-persist";
import { useDispatch, useSelector } from "react-redux";
import userListReducer from './userListSlice'
import workspaceListReducer from './workspaceListSlice'
import workspaceReducer from './workspaceSlice'
import chatUserReducer from './chatUserSlice'
import messageReducer from './messageSlice'
import messageListReducer from './messageListSlice'
import modalReducer from './modalSlice'
import channelReducer from './channelSlice'
import channelListReducer from './channelListSlice'
import memberListReducer from './memberListSlice'
import emojiReducer from './emojiSlice';
import emojiListReducer from './emojiListSlice'
import fileListReducer from './fileListSlice'
import profileUserReducer from './profileUserSlice'

const reducers = combineReducers({
    user: userReducer,
    userList : userListReducer,
    workspace : workspaceReducer,
    workspaceList : workspaceListReducer,
    chatUser : chatUserReducer,
    message : messageReducer,
    messageList : messageListReducer,
    modal : modalReducer,
    channel : channelReducer,
    channelList : channelListReducer,
    memberList : memberListReducer,
    emoji : emojiReducer,
    emojiList : emojiListReducer,
    fileList : fileListReducer,
    profileUser : profileUserReducer
})

const persistConfig = {
    key: "root",
    storage,
    whitelist:[
        'user', 
        'userList', 
        'workspace', 
        'workspaceList', 
        'chatUser', 
        'message', 
        'messageList',
        'modal',
        'channel',
        'channelList',
        'memberList',
        'emoji',
        'emojiList',
        'fileList',
        'profileUser'
    ]
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer : persistedReducer,
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware)=> getDefaultMiddleware({serializableCheck:false})
});

export const useChatDispatch = () => useDispatch();
export const useChatSelector = useSelector;

export const persistor = persistStore(store);