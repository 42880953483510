import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    list:[]
};

const fileListSlice = createSlice({
    name: 'fileList',
    initialState:initialState,
    reducers:{
        setFileList: (state:any, action:any)=>{
            localStorage.setItem('fileList', JSON.stringify(action.payload))
            state.list = action.payload
        },
        initFileList:(state:any) => {
            localStorage.removeItem('fileList');
            state.list = [];
        }
    },
    extraReducers :(builder)=>{
        // 로그아웃 reducer
        builder.addCase(PURGE, ()=>initialState);
    }
});

export const {setFileList, initFileList} = fileListSlice.actions;
export default fileListSlice.reducer;