import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { ChannelInfo } from "../types/ChannelInfo.type";

const initialState:ChannelInfo = {
    chiNum:0,
    woiNum:0,
    chiName:'',
    chiType:'',
    credat:'',
    cretim:'',
    lmodat:'',
    lmotim:'',
    active:'',
    unReadCnt:0
};

const channelSlice = createSlice({
    name: 'channel',
    initialState:initialState,
    reducers:{
        setChannel: (state:ChannelInfo, action:any)=>{
            state.chiNum = action.payload.chiNum;
            state.woiNum = action.payload.woiNum;
            state.chiName = action.payload.chiName;
            state.chiType = action.payload.chiType;
            state.credat = action.payload.credat;
            state.cretim = action.payload.cretim;
            state.lmodat = action.payload.lmodat;
            state.lmotim = action.payload.lmotim;
            state.active = action.payload.active;
            state.unReadCnt = action.payload.unReadCnt;
        },
        initChannel:() => initialState
    },
    extraReducers :(builder)=>{
        // 로그아웃 reducer
        builder.addCase(PURGE, ()=>initialState);
    }
});

export const {setChannel, initChannel} = channelSlice.actions;
export default channelSlice.reducer;