import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    chiNum : 0,
    list : []
}
const memberListSlice = createSlice({
    name : 'memberList',
    initialState: initialState,
    reducers:{
        setMemberList : (state:any, action:any)=>{
            state.chiNum = action.payload.chiNum;
            state.list = action.payload.list;
        },
        initMemberList:(state:any)=>{
            state.chiNum = 0;
            state.list = [];
        }
    },
    extraReducers : (builder) => {
        builder.addCase(PURGE, ()=> initialState)
    }
});

export const {setMemberList, initMemberList} = memberListSlice.actions;
export default memberListSlice.reducer;
