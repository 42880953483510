import { Nav } from './Nav';
import main from '../css/main.module.scss';
import '../css/common.scss';
import { Header } from './Header'
import { Content } from './Content';
import emoji from '@emoji-mart/data'
import { useMemo } from 'react';

export const Main = () => {
    useMemo(() => {
        const storedEmojiData = localStorage.getItem('emojiData');
        if (!storedEmojiData) {
            localStorage.setItem('emojiData', JSON.stringify(emoji));
            return emoji;
        } else {
            return JSON.parse(storedEmojiData);
        }
    }, []);
    return (
        <div className={main.wrap}>
            <Header></Header>
            <div className='df'>
                <Nav></Nav>
                <Content></Content>
            </div>
        </div>
    )
}