import Modal from 'react-modal';
import { useChatDispatch, useChatSelector } from '../store';
import { closeModal } from '../store/modalSlice';
import modal from '../css/modal.module.scss';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { UserInfo } from '../types/UserInfo.type';
import { setUserList } from '../store/userListSlice';
import { publishMsg } from '../service/ClientService';
import { apiUrl } from '../apis/axiosHttp';

export const AddUserModal = ()=>{
    const token = localStorage.getItem('token');
    const usiNum = localStorage.getItem('usiNum') || '0';
    const [error, setError] = useState<string>('');
    const dispatch = useChatDispatch();
    const isOpen = useChatSelector((state:any)=>state.modal.user.isOpen);
    const userList = useChatSelector((state:any)=>state.userList.list);
    const workspace = useChatSelector((state:any)=>state.workspace);
    const [user, setUser] = useState<UserInfo>({});
    const [validation, setValidation] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [chkSuccess, setChkSuccess] = useState<string>('');
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const handleCloseModal = () =>{
        setError('');
        setChkSuccess('');
        setUser({});
        dispatch(closeModal('user'));
    }

    const handleChangeEvt = async (evt:any) => {
        setUser({});
        setError('');
        setChkSuccess('');
        setValidation(false);
        setEmail(evt.target.value);
    }

    const emailValidation = async (email:string) => {
        setChkSuccess('');
        setError('');
        const isNotDuplicate = await userList.every((tmpUser:any) => tmpUser.usiEmail !== email);
        if(!email){
            setError('이메일을 입력해주세요.');
            setValidation(false);
            return;
        }
        if(!emailRegEx.test(email)){
            setError('이메일 형식에 어긋납니다.');
            setValidation(false);
            return;
        }
        if(!isNotDuplicate){
            setError('이미 존재하는 사용자입니다.');
            setValidation(false);
            return;
        }
        if(emailRegEx.test(email) && isNotDuplicate && email){
            setValidation(true);
            getUserInfo(email);
        }
    }

    const getUserInfo = async (email:string) => {
        const req = {
            usiEmail : email
        }
        if(validation){
            try{
                const res = await axios.post(`${apiUrl}/email-chk`, req,{
                    headers : {
                        "Content-Type" : 'application/json;charset=UTF-8',
                        Authorization : `Bearer ${token}`
                    }
                });
                if(res.data){
                    setUser(res.data);
                    setChkSuccess('초대 가능한 사용자입니다.');
                }
                if(!res.data){
                    //에러 표시
                    setError('존재하지 않는 사용자입니다.');
                }
            }catch(e){
                console.error(e);
            }
            
        }
    }

    const getUserList = async(init:boolean) => {
        if(init){
            try{
                const res = await axios.post(`${apiUrl}/user-infos`, workspace, {
                    headers : {
                        "Content-Type" : 'application/json;charset=UTF-8',
                        Authorization : `Bearer ${token}`
                    }
                });
                if(res.data.length){
                    dispatch(setUserList(res.data));
                }
            }catch(e){
                console.error(e);
            }
        }
    }

    const inviteUser = async (user:UserInfo) => {
        if(user.usiNum&&!error){
            const req = {
                woiNum : workspace.woiNum,
                usiNum : user.usiNum
            }
            try{
                const res = await axios.post(`${apiUrl}/insert-workspace-user`, req, {
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    Authorization : `Bearer ${token}`
                }
            });
            if(res.data){
                //모달 닫기
                setChkSuccess('');
                dispatch(closeModal('user'));
                getUserList(res.data);
                setUser({});
                const destination = `/publish/chat/${user.usiNum}`;
                publishMsg(destination, {
                    msiSenderUsiNum : usiNum,
                    msiReceiveUsiNum : `${user.usiNum}`,
                    msiType : 'INVITE_USER',
                    woiNum : workspace.woiNum,
                }, {}, [], {});
            }
            }catch(e){
                console.error(e);
            }
        }
        if(!user.usiNum){
            setError('확인 버튼을 눌러주세요.');
        }
    }

    useEffect(()=>{
        if(email){
            getUserInfo(email);
        }
    },[validation]);

    return (
        <Modal isOpen={isOpen} onRequestClose={handleCloseModal} className={modal.modalContent} overlayClassName={modal.modalOverlay}>
                    <div className={modal.modalTop} >
                        <h2 className={modal.modalTitle}>사용자 추가</h2>
                    </div>
                    <div className={modal.modalBody}>
                        <div className={modal.modalLabel}>
                            <label htmlFor="">이메일<span className='fc-r'>*</span></label>
                        </div>
                        <div className={modal.modalInputGroup}>
                        <input className={modal.modalInput} type="email" id="usiEmail" placeholder="이메일을 입력하세요." onChange={(evt)=>{handleChangeEvt(evt)}}></input>
                        {/* {inputChk&&<span className={auth.errmsg}>필수입력값입니다.</span>} */}
                        <button className="btn-p" onClick={() => {emailValidation(email)}}>확인</button>
                        </div>
                        {error&&<span className={modal.modalError}>{error}</span>}
                        {chkSuccess&&<span className={modal.modalSuccessMsg}>{chkSuccess}</span>}
                    </div>
                    <div className={modal.modalButtonGroup}>
                        <button className="btn-p" onClick={()=>{inviteUser(user)}}>초대</button>
                        <button className="btn-g" onClick={handleCloseModal}>닫기</button>
                    </div>
                </Modal>
    );
}