import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    list : []
}
const emojiListSlice = createSlice({
    name : 'emojiList',
    initialState: initialState,
    reducers:{
        setEmojiList : (state:any, action:any)=>{
            localStorage.setItem('emojiList', JSON.stringify(action.payload));
            state.list = action.payload;
        },
        initEmojiList:(state:any)=>{
            localStorage.removeItem('emojiList');
            state.list = [];
        }
    },
    extraReducers : (builder) => {
        builder.addCase(PURGE, ()=> initialState)
    }
});

export const {setEmojiList, initEmojiList} = emojiListSlice.actions;
export default emojiListSlice.reducer;
