import Modal from 'react-modal';
import { useChatDispatch, useChatSelector } from '../store';
import { closeModal } from '../store/modalSlice';
import modal from '../css/modal.module.scss';
import { useState } from 'react';
import { publishMsg } from '../service/ClientService';
import { initMessage } from '../store/messageSlice';
import { MessageInfo } from '../types/MessageInfo.type';
import { getDateTime } from '../service/DateService';

export const EditMessageModal = ()=>{
    const [editMsg, setEditMsg] = useState<string>('');
    const dispatch = useChatDispatch();
    const isOpen = useChatSelector((state:any)=>state.modal.editMessage.isOpen);
    const chatUser = useChatSelector((state:any)=>state.chatUser);
    const channel = useChatSelector((state:any)=>state.channel);
    const [changeChk, setChangeChk] = useState<boolean>(false);
    const [errorMsg, setErrorMsg] = useState<string>('');
    const message = useChatSelector((state:any)=>state.message);

    const handleCloseModal = () =>{
        setEditMsg('');
        setChangeChk(false);
        dispatch(initMessage());
        dispatch(closeModal('editMessage'));
        setErrorMsg('');
    }

    const handleChangeEvt = async (evt:any) => {
        setEditMsg(evt.target.value);
        setChangeChk(true);
        setErrorMsg('');
    }

    const editMessage = async (message:MessageInfo) => {
        if(!editMsg){
            setErrorMsg('내용을 입력해주세요.');
            return;
        }
        if(message.msiNum){
            const dateTime = getDateTime();
            const req = {
                msiNum : message.msiNum,
                msiMessage : editMsg,
                msiSenderUsiNum : message.msiSenderUsiNum,
                msiReceiveUsiNum : message.msiReceiveUsiNum,
                msiType : message.msiType,
                chiNum : message.chiNum,
                msiEditTime : dateTime,
                active : message.active
                }

                const destination = `/publish/chat/${chatUser.usiNum ? chatUser.usiNum : channel.chiNum}`;
                
                publishMsg(destination, req, {}, [], {});
        }

        setEditMsg('');
        setErrorMsg('');
        setChangeChk(false);
        dispatch(initMessage());
        dispatch(closeModal('editMessage'));
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={handleCloseModal} className={modal.modalContent} overlayClassName={modal.modalOverlay}>
                    <div className={modal.modalTop} >
                        <h2 className={modal.modalTitle}>메세지 편집</h2>
                    </div>
                    <div className={modal.modalBody}>
                        <div className={modal.modalLabel}>
                            <label className={modal.modalLabel} htmlFor="">메시지<span className='fc-r'>*</span></label>
                        </div>
                        <div className={modal.modalInputGroup}>
                        <input className={modal.modalInput} type="text" id="msiMessage" placeholder="메세지를 입력하세요." value={changeChk ? editMsg : message.msiMessage} onChange={(evt)=>{handleChangeEvt(evt)}}></input>
                        </div>
                        {errorMsg&&<span className={modal.modalError}>{errorMsg}</span>}
                    </div>
                    <div className={modal.modalButtonGroup}>
                        <button className="btn-p" onClick={()=>{editMessage(message)}}>편집</button>
                        <button className="btn-g" onClick={handleCloseModal}>닫기</button>
                    </div>
                </Modal>
    );
}