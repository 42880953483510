import Picker from '@emoji-mart/react'
import { useEffect, useMemo, useRef, useState } from "react";
import { useChatDispatch, useChatSelector } from '../store';
import axios from 'axios';
import { MessageInfo } from '../types/MessageInfo.type';
import { initMessageList, setMessageList } from '../store/messageListSlice';
import  messageStyle  from '../css/messageStyle.module.scss';
import { openModal } from '../store/modalSlice';
import { initMessage, setMessage } from '../store/messageSlice';
import { handleDate, handleTime } from '../service/DateService';
import { publishMsg } from '../service/ClientService';
import { initEmojiList, setEmojiList } from '../store/emojiListSlice';
import { setFileList } from '../store/fileListSlice';
import defaultImg from '../assets/img_default.png';
import { apiUrl } from '../apis/axiosHttp';

export const MessageList = ()=>{
    const token = localStorage.getItem('token');
    const chatUser = useChatSelector((state:any)=>state.chatUser);
    const channel = useChatSelector((state:any)=>state.channel);
    const user = useChatSelector((state:any)=>state.user);
    const workspace = useChatSelector((state:any)=>state.workspace);
    const message = useChatSelector((state:any)=>state.message);
    const messageList = useChatSelector((state:any)=>state.messageList);
    const memberList = useChatSelector((state:any)=>state.memberList);
    const emojiList = useChatSelector((state:any)=>state.emojiList.list);
    const fileList = useChatSelector((state:any)=>state.fileList.list);
    const [msgLoading, setMsgLoading] = useState<boolean>(false);
    const [showButtons, setShowButtons] = useState<any>(false);
    const wrapRef = useRef<HTMLDivElement>(null);
    const emojiModalRef = useRef<any>(null);
    // const emojiData = JSON.parse(localStorage.getItem('emojiData') || '{}');
    const emojiData = useMemo(() => {
        const storedEmojiData = localStorage.getItem('emojiData');
        return storedEmojiData ? JSON.parse(storedEmojiData) : {};
    }, [localStorage.getItem('emojiData')]);
    // const emojiData = useMemo(() => JSON.parse(localStorage.getItem('emojiData') || '{}'), []); // useMemo를 사용하여 캐시된 emojiData
    // const [messages, setMessages] = useState<MessageInfo[]>([]);
    const dispatch = useChatDispatch();
    const [showEmojiModal, setShowEmojiModal] = useState<boolean>(false);

    const handleOutsideClick = (evt:any) => {
        if (emojiModalRef.current && !emojiModalRef.current.contains(evt.target)) {
            // 클릭한 요소가 모달 영역 밖이면 모달 닫기
            dispatch(initMessage());
            setShowEmojiModal(false);
        } 
    };

    const toggleEmojiModal = (message:any) => {
        // 모달 토글 함수
        dispatch(setMessage(message)); // 선택된 메시지 설정
        setShowEmojiModal((prev) => !prev); // 모달 열기/닫기 토글
    };

    // const getEmojiList = (message:any,evt:any)=>{
    //     // localStorage.setItem('emoji', JSON.stringify(data));
    //     // evt.stopPropagation(); // 수정필요(검색기능 동작안함)
    //     if(emojiModalRef.current){
    //         dispatch(initMessage());
    //         setShowEmojiModal(false);
    //         emojiModalRef.current = null;
    //     } else {
    //         dispatch(setMessage(message));
    //         setShowEmojiModal(true);
    //     }
    // }
    const getMessageList = async()=>{
        setMsgLoading(true);
        dispatch(initMessageList());
        const req = {
            msiSenderUsiNum : user.usiNum,
            msiReceiveUsiNum : chatUser.usiNum,
            woiNum : workspace.woiNum
        }
        try{
            const res = await axios.post(`${apiUrl}/message-infos`, req, {
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    "Authorization" : `Bearer ${token}`
                }
            });
            const message:any = {
                msiSenderUsiNum : user.usiNum,
                msiReceiveUsiNum : chatUser.usiNum,
                chiNum : 0,
                list : res.data.messageList
            }
            if(res.data.emojiList){
                dispatch(setEmojiList(res.data.emojiList));
            }
            if(res.data.fileList){
                dispatch(setFileList(res.data.fileList));
            }
            if(res.data.messageList){
                dispatch(setMessageList(message));
            }
            if(!res.data.messageList){
                const messageObj:any = {
                    msiSenderUsiNum : 0,
                    msiReceiveUsiNum : 0,
                    chiNum : 0,
                    list : []
                }
                dispatch(setMessageList(messageObj));
            }
        }catch(e){
            console.error(e);
        }
        setMsgLoading(false);
    }

    const getChannelMessageList = async() =>{
        const req = {
            woiNum : workspace.woiNum,
            chiNum : channel.chiNum
        }
        try{
            const res = await axios.post(`${apiUrl}/channel-message-infos`, req, {
                headers : {
                    'Content-Type' : 'application/json;charset=UTF-8',
                    'Authorization' : `Bearer ${token}`
                }
            });

            const message:any = {
                msiSenderUsiNum : user.usiNum,
                mseReceiveUsiNum : chatUser.usiNum,
                chiNum : channel.chiNum,
                list : res.data
            };
            
            dispatch(setMessageList(message));
        }catch(e){
            console.error(e);
        }
    }

    const handleMouseEnter = (idx:any) => {
        setShowButtons((prevButtons: any)=>({
            ...prevButtons,
            [idx]: true
        }))
    }

    const handleMouseLeave = (idx:any) => {
        setShowButtons((prevButtons: any)=>({
            ...prevButtons,
            [idx]: false
        }))
    }

    const handleDeteBar = (prev:string, next:string) => {
        let prevDate ='';
        let nextDate = '';
        if(prev){
            prevDate = prev.slice(0,10);
        }
        if(next){
            nextDate = next.slice(0,10);
        }
        
        return (prevDate !== nextDate) ? true : false;
    }

    const handleOpenModal = (type:any, message:any) => {
        if(message.msiSenderUsiNum !== user.usiNum){
            return;
        }
        if(type === 'editMessage' || 'deleteMessage' ){
            dispatch(setMessage(message));
            dispatch(openModal(type));
        }
        if(type === 'profile'){
        }
        }
    
    const handleEmojiButton = (message:any, emoji:any) => {
        if(message.msiSenderUsiNum === user.usiNum){
            // emiType : 'DEL'
            const req = {
                emiNum : emoji.emiNum,
                emiType : 'DEL',
                active : '0'
            }
            const destination = `/publish/chat/${chatUser.usiNum ? chatUser.usiNum : channel.chiNum}`
            publishMsg(destination, message, req, [], {});
            return;
        }
        if(message.msiSenderUsiNum !== user.usiNum){
            // emiType : 'UPT'
            const req = {
                emiNum : emoji.emiNum,
                emiCount : emoji.emiCount+1,
                emiType : 'UDT',
            }
            const destination = `/publish/chat/${chatUser.usiNum ? chatUser.usiNum : channel.chiNum}`
            publishMsg(destination, message, req, [], {});
            return;
        }
    }

        const insertReaction = (emoji:any) => {
            const req = {
                msiNum : message.msiNum,
                emiId : emoji.id,
                emiNative : emoji.native,
                emiCount : 1,
                emiType : 'NEW',
                active : '1'
            }
            const destination = `/publish/chat/${chatUser.usiNum ? chatUser.usiNum : channel.chiNum}`
            publishMsg(destination, message, req, [], {});
            dispatch(initMessage());
            // 반응남긴후 모달 제거
            setShowEmojiModal(false);
        }

        const handleDownload = async (file:any) => {
            if(file.fiiType.startsWith('image/') || file.fiiType.startsWith('text/') || file.fiiType.startsWith('application/')){
                // 이미지저장로직
               const res = await axios.get(file.fiiUrl, {
                    responseType:'blob'
                });

                const blob = res.data;
                const image = new File([blob], file.fiiName, {type:blob.type});
                const link = document.createElement('a');
                link.href = URL.createObjectURL(image);
                link.download = file.fiiName;
                link.click();
                URL.revokeObjectURL(link.href);
                
                return;
            }
            window.open(file.fiiUrl, '_blank');
        }
    
    useEffect(()=>{
        if(token && chatUser?.usiNum){
            dispatch(initEmojiList());
            getMessageList();
        }
        if(token && channel?.chiNum){
            dispatch(initEmojiList());
            getChannelMessageList();
        }
        
    },[chatUser, channel]);

    useEffect(()=>{
        // 스크롤이 맨 아래일 때만 스크롤을 맨 아래로 이동
        const wrapElement = wrapRef.current;
        if(wrapElement){
        const isScrolledToBottom = wrapElement.scrollHeight - wrapElement.clientHeight <= wrapElement.scrollTop + 20;
        // if(isScrolledToBottom){
        //     wrapElement.scrollTop = wrapElement.scrollHeight;
        // }
            // 스크롤이 맨 아래일 때만 스크롤을 맨 아래로 이동
            // if(isScrolledToBottom){
            //     wrapElement.scrollTop = wrapElement.scrollHeight;
            // }
            wrapElement.scrollTop = wrapElement.scrollHeight;
        }
    },[messageList])

    useEffect(() => {
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);
    return (
        <>
        {
          msgLoading && 
          <div className="spinnerWrap">
            <div className="spinner"></div>
          </div>
        }
        {
            messageList.list && messageList.list.length > 0 ? (
                <div ref={wrapRef} className={messageStyle.wrap}>
                {messageList.list.map((message: MessageInfo, idx: number) => (
                <div key={idx}>
                    {
                        handleDeteBar((idx === 0 ? null : messageList.list[idx-1].msiSentTime), (messageList.list[idx].msiSentTime ? messageList.list[idx].msiSentTime : null))&&
                        <div className={messageStyle.dateBar}>
                        <div className={messageStyle.dateLine}></div>
                        <div className={messageStyle.dateText}>{handleDate(message.msiSentTime || '')}</div>
                        <div className={messageStyle.dateLine}></div>
                        </div>
                    }
                <div style={{display: "flex"}}>
                    {/* 프로필 이미지 */}
                    <div className={messageStyle.messageProfileWrap}>
                        <img className={messageStyle.messageProfilePreview} src={(()=>{
                            if(channel.chiNum && message.msiSenderUsiNum){
                                const member = memberList.list.find((member:any)=> member?.usiNum === message.msiSenderUsiNum);
                                return member ? member.usiImgUrl : defaultImg;
                            } else {
                                return message.msiSenderUsiNum === user.usiNum ? 
                                (user.usiImgUrl ? user.usiImgUrl : defaultImg) : 
                                (chatUser.usiImgUrl ? chatUser.usiImgUrl : defaultImg)
                            }
                        })()}/>
                    </div>
                    <div style={{width: "100%"}}>
                        {/* 보낸 사람 정보 */}
                        <div className={messageStyle.content}>
                            <div className={messageStyle.author} onClick={()=>{}}>
                            {
                                (channel.chiNum && message.msiSenderUsiNum)
                                ?
                                (
                                memberList.list.find((member:any) => member?.usiNum === message.msiSenderUsiNum)?.usiName
                                )
                                :
                                (
                                message.msiSenderUsiNum === user.usiNum ? user.usiName : chatUser.usiName
                                )
                            }
                            </div>
                        <span className={messageStyle.timeStamp}>{handleTime(message.msiSentTime || '')}</span>
                        </div>
                        {/* 메세지 내용 */}
                        <div className={messageStyle.message} onMouseEnter={()=>{handleMouseEnter(idx)}} onMouseLeave={()=>{handleMouseLeave(idx)}}>
                        {
                                message.active==='1' ? (
                            <>
                            <div className={messageStyle.text}>{message.msiMessage}{message.msiEditTime ? <span className={messageStyle.edited}>(편집됨)</span> : ''}</div>
                            {
                            /* {여기는 파일리스트가 있는 경우 msiNum과 맞춰서 렌더 div} */
                            fileList && fileList.length > 0 && fileList.map((file:any, idx:number)=> (
                                (file.msiNum === message.msiNum) &&
                                <div key={idx} className={messageStyle.messageFileWrap}>
                                    {file.fiiType.startsWith('image/') ? (
                                    <img className={messageStyle.messageFileImg} src={file.fiiUrl}/>)
                                    :(<span className={messageStyle.messageFileTxt}>{file.fiiName}</span>)
                                    }
                                    <button className={messageStyle.messageFileButton} onClick={()=>{handleDownload(file)}}>다운로드</button>
                                </div>

                            ))
                            }
                        {showButtons[idx] && (
                            <div className={messageStyle.buttons}>
                            <button onClick={()=>{handleOpenModal('editMessage', message)}}>편집</button>
                            <button onClick={()=>{handleOpenModal('deleteMessage', message)}}>삭제</button>
                            <button onClick={()=>{toggleEmojiModal(message)}}>반응</button>
                            </div>
                        )}
                        </>
                        )
                        :
                        <p className={messageStyle.deleted}>삭제된 메세지 입니다.</p>
                        }
                        </div>
                    </div>
                </div>
                
                
                {/* 수정 버튼 */}
                <div className={messageStyle.reactionButtons}>
                { 
                    emojiList && emojiList.length>0 && emojiList.map((emoji:any, idx:number)=> (
                        ((emoji.msiNum === message.msiNum) && (emoji.active === '1')) &&
                        <button className={messageStyle.reactionButton} key={idx} onClick={()=>{handleEmojiButton(message, emoji)}}>
                            {emoji.emiNative} {emoji.emiCount}
                        </button>
                    ))
                }
                </div>
                {showEmojiModal && 
                <div className={messageStyle.emojiModal} ref={emojiModalRef}>
                <Picker data={emojiData} onEmojiSelect={(emoji:any)=>{insertReaction(emoji)}} locale='kr' theme='auto' dynamicWidth={false} emojiSize={18}></Picker>
                </div>
                }
                </div>
            ))
        }
        </div>
        
        ) : (
            <div className={messageStyle.emptyMessage}>
                {msgLoading || !chatUser.usiNum ? '' : '메세지가 없습니다.'}
            </div>
        )}
        </>
    )
}