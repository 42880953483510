import { createSlice } from "@reduxjs/toolkit";
import { UserInfo } from "../types/UserInfo.type";
import { PURGE } from "redux-persist";

const initialState:UserInfo = {
    usiNum:0,
    usiId:'',
    usiEmail:'',
    usiPwd:'',
    usiName:'',
    usiTitle:'',
    usiStat:'',
    usiImgName:'',
    usiImgUuid:'',
    usiImgPath:'',
    usiImgUrl:'',
    credat:'',
    cretim:'',
    lmodat:'',
    lmotim:'',
    active:'',
    unReadCnt:0
};

const chatUserSlice = createSlice({
    name: 'chatUser',
    initialState:initialState,
    reducers:{
        setChatUser: (state:UserInfo, action:any)=>{
            state.usiNum = action.payload.usiNum;
            state.usiId = action.payload.usiId;
            state.usiEmail = action.payload.usiEmail;
            state.usiPwd = action.payload.usiPwd;
            state.usiName = action.payload.usiName;
            state.usiTitle = action.payload.usiTitle;
            state.usiStat = action.payload.usiStat;
            state.usiImgName = action.payload.usiImgName;
            state.usiImgUuid = action.payload.usiImgUuid;
            state.usiImgPath = action.payload.usiImgPath;
            state.usiImgUrl = action.payload.usiImgUrl;
            state.credat = action.payload.credat;
            state.cretim = action.payload.cretim;
            state.lmodat = action.payload.lmodat;
            state.lmotim = action.payload.lmotim;
            state.active = action.payload.active;
            state.unReadCnt = action.payload.unReadCnt;
        },
        initChatUser:() => initialState
    },
    extraReducers :(builder)=>{
        // 로그아웃 reducer
        builder.addCase(PURGE, ()=>initialState);
    }
});

export const {setChatUser, initChatUser} = chatUserSlice.actions;
export default chatUserSlice.reducer;