import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { WorkspaceInfo } from "../types/WorkspaceInfo.type";

interface WorkspaceList {
    list: WorkspaceInfo[];
}

const initialState:WorkspaceList = {
    list : []
}
const workspaceListSlice = createSlice({
    name : 'workspaceList',
    initialState: initialState,
    reducers:{
        setWorkspaceList : (state:any, action:any)=>{
            localStorage.setItem('workspaceList', JSON.stringify(action.payload));
            state.list = action.payload;
        },
        initWorkspaceList:(state:any)=>{
            state.list = [];
        }
    },
    extraReducers : (builder) => {
        builder.addCase(PURGE, ()=> {
            return initialState
        })
    }
});

export const {setWorkspaceList, initWorkspaceList} = workspaceListSlice.actions;
export default workspaceListSlice.reducer;
