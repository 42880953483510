import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { WorkspaceInfo } from "../types/WorkspaceInfo.type";

const initialState:WorkspaceInfo = {
    woiNum:0,
    woiName:'',
    woiTitle:'',
    credat:'',
    cretim:'',
    lmodat:'',
    lmotim:'',
    active:''
};

const workspaceSlice = createSlice({
    name: 'workspace',
    initialState:initialState,
    reducers:{
        setWorkspace: (state:WorkspaceInfo, action:any)=>{
            state.woiNum = action.payload.woiNum;
            state.woiName = action.payload.woiName;
            state.woiTitle = action.payload.woiTitle;
            state.credat = action.payload.credat;
            state.cretim = action.payload.cretim;
            state.lmodat = action.payload.lmodat;
            state.lmotim = action.payload.lmotim;
            state.active = action.payload.active;
        },
        initWorkspace:(state:WorkspaceInfo) => {
            // state = initialState;
            state.woiNum = initialState.woiNum;
            state.woiName = initialState.woiName;
            state.woiTitle = initialState.woiTitle;
            state.credat = initialState.credat;
            state.cretim = initialState.cretim;
            state.lmodat = initialState.lmodat;
            state.lmotim = initialState.lmotim;
            state.active = initialState.active;
        }
    },
    extraReducers :(builder)=>{
        // 로그아웃 reducer
        builder.addCase(PURGE, ()=>initialState);
    }
});

export const {setWorkspace, initWorkspace} = workspaceSlice.actions;
export default workspaceSlice.reducer;