import { Client } from "@stomp/stompjs";
import { MessageInfo } from "../types/MessageInfo.type";
import { EmojiInfo } from "../types/EmojiInfo.type";
import { FileInfo } from "../types/FileInfo.type";
import { UserInfo } from "../types/UserInfo.type";

const brokerURL = `${process.env.REACT_APP_WS}://${process.env.REACT_APP_HOST}:${process.env.REACT_APP_PORT}/chat`;

const client = new Client({
    brokerURL : brokerURL,
    debug : (str) =>{

    },
    reconnectDelay:1000,
    connectionTimeout:100
});

export const initClient = async (configs:any[])=>{
    return new Promise((resolve, reject)=>{
        if(!localStorage.getItem('usiNum') || !localStorage.getItem('token')){
            reject('login');
        }
        client.connectHeaders = {
            usiNum : localStorage.getItem('usiNum') || '',
            chiNum : localStorage.getItem('chiNum') || '',
            token : localStorage.getItem('token') || '',
        }
        client.onConnect = () => {
            for(const config of configs){
                client.subscribe(config.url, config.callback);
            }
            resolve(client);
        }
        client.activate();
    })
}

export const disconnectClient = () =>{
    if(client.connected){
        client.deactivate();
    }
}

export const publishMsg = (destination:string, message:MessageInfo, emoji:EmojiInfo, fileList:FileInfo[], user:UserInfo)=>{
    client.publish({
        destination: destination,
        body : JSON.stringify({message : message, emoji : emoji, fileList : fileList, user : user })
    })
}
