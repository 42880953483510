import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

const initialState = {
    list:[]
};

const channelListSlice = createSlice({
    name: 'channelList',
    initialState:initialState,
    reducers:{
        setChannelList: (state:any, action:any)=>{
            localStorage.setItem('channelList', JSON.stringify(action.payload))
            state.list = action.payload
        },
        initChennelList:(state:any) => {
            state = initialState;
        }
    },
    extraReducers :(builder)=>{
        // 로그아웃 reducer
        builder.addCase(PURGE, ()=>initialState);
    }
});

export const {setChannelList} = channelListSlice.actions;
export default channelListSlice.reducer;