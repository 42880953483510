import { useRef, useState } from "react";
import { useChatDispatch, useChatSelector } from "../store";
import { publishMsg } from "../service/ClientService";
import editor from '../css/editor.module.scss';
import { getDateTime, getMonth, getYear } from "../service/DateService";
import { FileInfo } from "../types/FileInfo.type";
import { setFileList } from "../store/fileListSlice";
import { storage } from '../apis/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import setUUID from 'uuid-random'

export const Editor = () => {
  const user = useChatSelector((state:any)=>state.user);
  const chatUser = useChatSelector((state:any)=>state.chatUser);
  const channel = useChatSelector((state:any)=>state.channel);
  const workspace = useChatSelector((state:any)=>state.workspace);
  const [inputValue, setInputValue] = useState<string>('');
  const [files, setFiles] = useState<any[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);
  const dispatch = useChatDispatch();
  const fileInputRef = useRef<any>(null);

  const sendFiles = async () => {
    // 파이어베이스 파일업로드
    if(!files.length && !inputValue.trim().replace(/\n/g, '')){
      return;
    }
    setInputValue('');
    if(!files.length){
      sendMessage([]);
      return;
    }

    if(files.length){
      setFiles([]);
      try{
        setUploading(true);
        const tmpFileList = JSON.parse(localStorage.getItem('fileList') || '[]');
        const year = getYear();
        const month = getMonth();
        const tmpFiles:any = await Promise.all(
          files?.map( async (file:any) => {
            const UUID = setUUID();
            const uploadPath = `/${user.usiNum}/${year}/${month}`
            const uploadUrl = `upload/files${uploadPath}/${UUID}_${file.name}`;
            const extention = getExtention(file.name);
            const storageRef = ref(storage, uploadUrl);
  
            await uploadBytesResumable(storageRef, file);
  
            const downloadUrl = await getDownloadURL(storageRef);
            const tmpFile:FileInfo = {
              fiiPath : uploadPath,
              fiiName : file.name,
              fiiUuid : UUID,
              fiiExt : extention,
              fiiType : file.type,
              fiiUrl : downloadUrl
            }
            return tmpFile;
          })
        )
        tmpFileList.push(tmpFiles);
        dispatch(setFileList(tmpFileList));
        sendMessage(tmpFiles);
      }catch(e){
        console.error(e);
      }
      setUploading(false);
    }
  }

  const sendMessage = async (tmpFiles:FileInfo[]) => {
    const dateTime = getDateTime();
    const destination = `/publish/chat/${chatUser.usiNum ? chatUser.usiNum : channel.chiNum}`;
    publishMsg(destination,{
      msiMessage : inputValue,
      msiSenderUsiNum : user.usiNum,
      msiReceiveUsiNum : chatUser.usiNum ? chatUser.usiNum : 0,
      msiSentTime : dateTime,
      msiType : chatUser.usiNum ? 'DIRECT_MESSAGE' : 'CHANNEL_MESSAGE',
      woiNum : workspace.woiNum,
      chiNum : channel.chiNum ? channel.chiNum : 0,
      active : '1'
    }, {}, tmpFiles, {})
    
  }

  const getMessage = (evt:any)=>{
    setInputValue(evt.target.value);
  }

  const handlekeyUp = (evt:any) => {
    if(evt.key === 'Enter'){
      sendFiles();
    }
  }

  const handleFileUpload = () => {
    if(fileInputRef.current){
      // 업로드 버튼 클릭시 input file 데이터 초기화 추가
      fileInputRef.current.value = '';
    }
  }

  const handleFileChange = async (evt:any) => {
      // 파이어베이스 로직으로 변경
      const file = fileInputRef.current.files[0];
      setFiles([...files, file]);
}
  const handleFileDelete = (idx:number) => {
    // let tmpFileList = JSON.parse(localStorage.getItem('fileList') || '[]');
    // tmpFileList = tmpFileList.filter((tmpFile:any)=>tmpFile.fiiName !== file.fiiName);
    
    // dispatch(setFileList(tmpFileList));
    // 파이어베이스로 변경
    let tmpFiles = [...files];
    tmpFiles.splice(idx, 1);
    setFiles(tmpFiles);
    
  }

  const getExtention = (fileName:string) => {
    const lastIdx = fileName.lastIndexOf('.');
    const extention = fileName.slice(lastIdx);
    return extention;
  }

  return (
    <>
    <div className={editor.wrap}>
      <div className={editor.content}>
        <div>
        <label className={editor.fileLabel} htmlFor="file" onClick={handleFileUpload}>파일업로드</label>
        <input type="file" id="file" ref={fileInputRef} onChange={(evt)=>{handleFileChange(evt)}} hidden disabled={uploading}/>
        </div>
        <div className={editor.textAreaWrapper}>
        <textarea id="msiMesssage" className={editor.textArea} onChange={getMessage} value={inputValue} onKeyUp={(evt)=>{handlekeyUp(evt)}} disabled={uploading}>
        </textarea>
        </div>
        {
          uploading && 
          <div className={editor.spinnerWrap}>
            <div className={editor.spinner}></div>
          </div>
        }
        <div className={editor.fileList}>
        {
         files.length > 0 && files.map((file:any, idx:number) => (
          <div key={idx} className={editor.fileWrap}>
            {
            file.type.startsWith('image/') ? 
            (<img className={editor.filePreview} src={URL.createObjectURL(file)}/>) :
            (<span className={editor.fileText}>{getExtention(file.name)}</span>)
            }
            <button className={editor.cancelButton} onClick={()=>{handleFileDelete(idx)}}>X</button>
          </div>
        ))
        }
        </div>
        <button className={editor.sendButton} onClick={sendFiles} disabled={uploading}>전송</button>
      </div>
  </div>
    </>
  );
};