import { useState } from "react"
import { WorkspaceInfo } from "../types/WorkspaceInfo.type"
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useChatSelector } from "../store";
import {ReactComponent as LogoImg} from '../assets/logo.svg';
import auth from '../css/auth.module.scss';
import { apiUrl } from "../apis/axiosHttp";

export const AddWorkspace = ()=>{
    const token = localStorage.getItem('token');
    const addWorkspaceWrap = {
        gap: '36px',
        margin: 'calc((100vh - 425px)/2) auto'
    }
    const [workspaceInfo, setWorkspaceInfo] = useState<WorkspaceInfo>({});
    const [inputChk, setInputChk] = useState<boolean>(false);
    const user = useChatSelector((state:any)=>state.user);
    const navigate = useNavigate();
    const inputEvt = (evt:any)=>{
        if(evt.target.id==='woiName'&&evt.target.value){
            setInputChk(false);
        }
        if(evt.target.id==='woiName'&&!evt.target.value){
            setInputChk(true);
        }
        setWorkspaceInfo({
            ...workspaceInfo,
            [evt.target.id] : evt.target.value
        });
        
    }
    const insertWorkspace = async ()=>{
        if(!workspaceInfo.woiName){
            setInputChk(true);
            return;
        }
        try{
            const res = await axios.post(`${apiUrl}/insert-workspace`, workspaceInfo,
            {
                headers: {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    "Authorization" : `Bearer ${token}`
                }
            });
            if(res.data){
                const woiNum = res.data;
                insertWorkspaceUser(user.usiNum, woiNum);
            }
        }catch(e){
            console.error(e);
        }
    }

    const insertWorkspaceUser = async (usiNum:number, woiNum:number) => {
        const req = {
            usiNum : usiNum,
            woiNum : woiNum
        }
        try{
            const res = await axios.post(`${apiUrl}/insert-workspace-user`, req, {
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    "Authorization" : `Bearer ${token}`
                }
            });
            if(res.data){
                navigate('/workspace');
            }
        }catch(e){
            console.error(e);
        }
        
        
    }
    return (
        <div className={auth.wrap} style={addWorkspaceWrap}>
            <LogoImg className={auth.logo}/>
            <div className={auth.iwrap}>
                <div className={auth.input}>
                    <label htmlFor="">워크스페이스명 <span className='fc-r'>*</span></label>
                    <input type="text" id="woiName" placeholder="워크스페이스명을 입력하세요." onChange={inputEvt}></input>
                    {inputChk&&<span className={auth.errmsg}>필수입력값입니다.</span>}
                </div>
                <div className={auth.input}>
                    <label htmlFor="">워크스페이스 설명</label>
                    <textarea id="woiTitle" placeholder="워크스페이스 설명을 입력하세요." onChange={inputEvt}></textarea>
                </div>
            </div>
            <div className={auth.bwrap}>
                <button className="btn-p" onClick={insertWorkspace}>워크스페이스 생성</button>
                <button className="btn-g" onClick={()=>{navigate('/workspace')}}>이전으로</button>
            </div>
        </div>
    )
}