import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { EmojiInfo } from "../types/EmojiInfo.type";

const initialState:EmojiInfo = {
    emiNum : 0,
    msiNum : 0,
    emiId : '',
    emiNative : '',
    emiCount : 0,
    emiType : '',
    credat : '',
    cretim : '',
    lmodat : '',
    lmotim : '',
    active : '',
}
const emojiSlice = createSlice({
    name : 'emoji',
    initialState: initialState,
    reducers:{
        setEmoji : (state:EmojiInfo, action:any)=>{
            state.emiNum = action.payload.emiNum;
            state.msiNum = action.payload.msiNum;
            state.emiId = action.payload.emiId;
            state.emiNative = action.payload.emiNative;
            state.emiCount = action.payload.emiCount;
            state.emiType = action.payload.emiType;
            state.credat = action.payload.credat;
            state.cretim = action.payload.cretim;
            state.lmodat = action.payload.lmodat;
            state.lmotim = action.payload.lmotim;
            state.active = action.payload.active;
        },
        initEmoji:(state:EmojiInfo)=>{
            return initialState;
        }
    },
    extraReducers : (builder) => {
        builder.addCase(PURGE, ()=> initialState)
    }
});

export const {setEmoji, initEmoji} = emojiSlice.actions;
export default emojiSlice.reducer;
