import Modal from 'react-modal';
import { closeModal } from '../store/modalSlice';
import modal from '../css/modal.module.scss';
import { useChatDispatch, useChatSelector } from '../store';
import { initProfileUser } from '../store/profileUserSlice';
import  profile  from '../css/profile.module.scss';
import defaultImg from '../assets/img_default.png';
import { useEffect, useRef, useState } from 'react';
import { getMonth, getYear } from '../service/DateService';
import setUUID from 'uuid-random'
import { storage } from '../apis/firebase';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { UserInfo } from '../types/UserInfo.type';
import { publishMsg } from '../service/ClientService';
import { setUser } from '../store/userSlice';

import onlineImg from '../assets/ellipse_yellow.png'
import offlineImg from '../assets/ellipse_gray.png'
import enabledImg from '../assets/ellipse_green.png'
import disabledImg from '../assets/ellipse_red.png'

import axios from 'axios';
import { apiUrl } from '../apis/axiosHttp';
export const ProfileModal = ()=>{

    const isOpen = useChatSelector((state:any)=>state.modal.profile.isOpen);
    const loginUser = useChatSelector((state:any)=>state.user);
    const profileUser = useChatSelector((state:any)=>state.profileUser);
    const [chkEditable, setChkEditable] = useState<boolean>(false);
    const fileInputRef = useRef<any>(null);
    const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);
    const [imageFile, setImageFile] = useState<any>(null);
    const [updateUser, setUpdateUser] = useState<UserInfo>({});
    const [inputEditable, setInputEditable] = useState<boolean>(false);
    const [imgTypeError, setImgTypeError] = useState<string>('');
    const [nameError, setNameError] = useState<string>('');
    const [emailError, setEmailError] = useState<string>('');
    const [emailChange, setEmailChange] = useState<boolean>(false);
    const [emailChkBtnClick, setEmailChkBtnClick] = useState<boolean>(false);
    const [emailSuccessMsg, setEmailSuccessMsg] = useState<string>('');
    const workspace = useChatSelector((state:any)=>state.workspace);
    const emailRegEx = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const dispatch = useChatDispatch();

    const handleCloseModal = () =>{
        setEmailSuccessMsg('');
        setEmailChange(false);
        setEmailChkBtnClick(false);
        setImgTypeError('');
        setEmailError('');
        setNameError('');
        setUpdateUser({});
        setInputEditable(false);
        setImageUrl(null);
        setImageFile(null);
        setChkEditable(false);
        dispatch(initProfileUser());
        dispatch(closeModal('profile'));
    }

    const handleUploadButton = () => {
        fileInputRef.current.value = '';
        setImgTypeError('');
        setNameError('');
        setEmailError('');
        if(fileInputRef.current){
            fileInputRef.current.click();
        }
    }
    const handleFileChange = (evt:any) => {
        const file = evt.target.files[0];
        if(file && !file.type.startsWith('image/')){
            console.log('이미지파일아님');
            setImgTypeError('이미지 형식만 가능합니다.');
            return;
        }
        const reader = new FileReader();
            if(file){
                reader.onloadend = () => {
                    setImageUrl(reader.result);
                }
                reader.readAsDataURL(file);
                setImageFile(file);
            }
    }

    const handleUpdateButton = async () => {
        // 내상태는 이 함수에서 바로 dispatch
        setImgTypeError('');
        if(!updateUser.usiNum && !imageFile){
            setImgTypeError('변경사항이 없습니다.');
            return;
        }
        if(!updateUser.usiNum && imageFile){
            // 업로드파일만 업로드 했을 경우 process 진행가능토록 수정
            const updateFile = await uploadFile();
            const updateFileUser = {
                ...profileUser, 
                usiImgName : updateFile?.usiImgName,
                usiImgUuid : updateFile?.usiImgUuid,
                usiImgUrl : updateFile?.usiImgUrl,
                usiImgPath : updateFile?.usiImgPath
            }
            updateProfile(updateFileUser);
            return;
        }
        // 수정사항이 존재할경우
        if(!updateUser.usiName){
            setNameError('이름은 필수값 입니다.');
            return;
        }
        if(!updateUser.usiEmail){
            setEmailError('이메일은 필수값 입니다.');
            return;
        }
        if(!emailChkBtnClick && emailChange){
            setEmailError('확인버튼을 눌러주세요.');
            return;
        }
        if(imageFile){
           const updateFile = await uploadFile();
           const updateFileUser = {
            ...updateUser, 
            usiImgName : updateFile?.usiImgName,
            usiImgUuid : updateFile?.usiImgUuid,
            usiImgUrl : updateFile?.usiImgUrl,
            usiImgPath : updateFile?.usiImgPath
        }
            updateProfile(updateFileUser);
        }
        if(!imageFile){
            updateProfile(updateUser);
        }
    }

    const uploadFile = async () => {
        const year = getYear();
        const month = getMonth();
        const uploadPath = `/${year}/${month}`;
        const UUID = setUUID();
        const uploadUrl = `upload/imgs${uploadPath}/${UUID}_${imageFile.name}`;
        const storageRef = ref(storage, uploadUrl);
        
        try{
            await uploadBytesResumable(storageRef, imageFile);
  
            const downloadUrl = await getDownloadURL(storageRef);
            
            const tmpUserFile:UserInfo = {
                usiImgName : imageFile.name,
                usiImgUuid : UUID,
                usiImgUrl : downloadUrl,
                usiImgPath : uploadPath
            }
            return tmpUserFile;
        }catch(e){
            console.error(e);
        }
    }

    const updateProfile = async (req:any) => {
        try{
            const destination = `/publish/chat/${profileUser.usiNum}`;
            publishMsg(destination, {
                msiType : 'UPDATE_USER',
                woiNum : workspace.woiNum
            },{},[],req);
            
            // const tmpUserList = JSON.parse(localStorage.getItem('userList') || '[]');
            // const updateUserList = tmpUserList.map((tmpUser:any) => {
            //     if(tmpUser.usiNum === updateUser.usiNum){
            //     return updateUser;
            //     }
            //     if(tmpUser.usiNum !== updateUser.usiNum){
            //     return tmpUser;
            //     }
            // });
            dispatch(setUser(req));
            // dispatch(setUserList(updateUserList));
            handleCloseModal();
            
            return;
        }
        catch(e){
            console.error(e);
        }
    }

    const handleInputChange = (evt:any) => {
        setEmailChkBtnClick(false);
        setEmailError('');
        setNameError('');
        setImgTypeError('');
        setEmailSuccessMsg('');
        setInputEditable(true);
        
        if(evt.target.type==='radio'){
            evt.target.id = 'usiStat'
        }
        if(evt.target.type==='email'){
            if(evt.target.value !== profileUser.usiEmail){
                // 기존의 이메일과 다를 경우만 true, validation 필요
                setEmailChange(true);
            }
        }
        
        setUpdateUser({
            ...profileUser,
            ...updateUser,
            [evt.target.id] : evt.target.value
        });
    }

    const handleUserStat = (usiStat:any) => {
        if(usiStat === 'ONLINE'){
            return <span>온라인<img className={modal.statImg} src={onlineImg}/></span>
        }
        if(usiStat === 'ENABLED'){
            return <span>대화가능<img className={modal.statImg} src={enabledImg}/></span>;
        }
        if(usiStat === 'DISABLED'){
            return <span>부재중<img className={modal.statImg} src={disabledImg}/></span>;
        }
        if(usiStat === 'OFFLINE'){
            return <span>오프라인<img className={modal.statImg} src={offlineImg}/></span>;
        }
    }

    const handleEmailChk = async () => {
        setEmailChkBtnClick(true);
        if(!emailChange){
            // 이메일 변경사항이 없는경우 로직 x
            return;
        }
        if(!updateUser.usiEmail){
            setEmailError('이메일은 필수값 입니다.');
            return;
        }
        if(!emailRegEx.test(updateUser.usiEmail || '')){
            // 이메일 형식 틀렸을 경우
            setEmailError('이메일 형식이 아닙니다.');
            return;
        }
        // 중복확인
        try{
            const res = await axios.post(`${apiUrl}/email-chk`, updateUser,{
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8'
                }
            });
            if(res.data){
                setEmailError('중복된 이메일입니다.');
                return;
            }
            if(!res.data){
                setEmailError('');
                setEmailSuccessMsg('사용 가능한 이메일입니다.');
                return;
            }
        }catch(e){
            console.error(e);
        }
        
    }


    useEffect(()=>{
        if(loginUser?.usiNum === profileUser?.usiNum){
            setChkEditable(true);
        }
    },[profileUser])


    const colorMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.target.checked
            ? document.documentElement.setAttribute("data-theme", "dark")
            : document.documentElement.setAttribute("data-theme", "light");
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={handleCloseModal} className={modal.modalContent} overlayClassName={modal.modalOverlay}>
                    <div className={modal.modalTop} >
                        <h2 className={modal.modalTitle}>프로필</h2>
                    </div>
                    <div className={modal.modalBody}>
                        <>
                            <div>
                                <div className={profile.profileImg}>
                                    <img src={
                                        (profileUser.usiImgUrl || imageUrl)
                                        ? 
                                        (imageUrl ? imageUrl.toString() : profileUser.usiImgUrl) 
                                        :
                                        defaultImg
                                        }/>
                                </div>
                                {chkEditable && 
                                    <>
                                    <button className={profile.uploadButton} onClick={handleUploadButton}>업로드</button>
                                    <input type="file" id="file" ref={fileInputRef} onChange={(evt)=>{handleFileChange(evt)}} hidden/>
                                    {imgTypeError && 
                                    <div className={modal.modalErrorWrap}>
                                        <span className={modal.modalError}>{imgTypeError}</span>
                                    </div>
                            }
                                    </>    
                                }
                            </div>
                            {chkEditable && 
                            <div className={modal.modalLabel}>
                                <label htmlFor="">이름</label>
                            </div>
                            }
                            <div className={modal.modalInputGroup}>
                                {chkEditable
                                ?
                                (<input className={modal.modalInput} type="text" id="usiName" onChange={(evt)=>{handleInputChange(evt)}} value={inputEditable ? updateUser.usiName : profileUser.usiName}></input>)
                                :
                                (<p className={profile.profileText}>{profileUser.usiName}</p>)
                                }
                            </div>
                            {nameError && 
                            <div className={modal.modalErrorWrap}>
                                <span className={modal.modalError}>{nameError}</span>
                            </div>
                            }
                            {chkEditable &&
                            <div className={modal.modalLabel}>
                                <label htmlFor="">이메일</label>
                            </div>
                            }
                            <div className={modal.modalInputGroup}>
                                {chkEditable
                                ?
                                (<><input className={modal.modalInput} type="email" id="usiEmail" onChange={(evt)=>{handleInputChange(evt)}} value={inputEditable ? updateUser.usiEmail : profileUser.usiEmail}></input>
                                <button className="btn-p" onClick={handleEmailChk}>확인</button></>)
                                :
                                (<p className={profile.profileText}>{profileUser.usiEmail}</p>)
                                }
                            </div>
                            {emailError && 
                            <div className={modal.modalErrorWrap}>
                                <span className={modal.modalError}>{emailError}</span>
                            </div>
                            }
                            {emailSuccessMsg && 
                            <div className={modal.modalErrorWrap}>
                                <span className={modal.modalSuccessMsg}>{emailSuccessMsg}</span>
                            </div>
                            }
                            {chkEditable &&
                            <>
                            <div className={modal.modalLabel}>
                                <label htmlFor="">상태</label>
                            </div>
                            <div className={modal.modalInputGroup}>
                                <div className={modal.modalRadioWrap}>
                                    <label className={modal.modalLabel} htmlFor="enabled">대화가능</label>
                                    <input type='radio' id='enabled' value='ENABLED' name='usiStat' onChange={(evt)=>{handleInputChange(evt)}} checked={updateUser.usiStat ? updateUser.usiStat === 'ENABLED' : (profileUser.usiStat && profileUser.usiStat === 'ENABLED')}></input>
                                </div>
                                <div className={modal.modalRadioWrap}>
                                    <label className={modal.modalLabel} htmlFor="disabled">부재중</label>
                                    <input type='radio' id='disabled' value='DISABLED' name='usiStat' onChange={(evt)=>{handleInputChange(evt)}} checked={updateUser.usiStat ? updateUser.usiStat === 'DISABLED' : (profileUser.usiStat && profileUser.usiStat === 'DISABLED')}></input>
                                </div>
                            </div>
                            <div className={modal.modalLabel}>
                                <label htmlFor="">색상</label>
                            </div>
                            <div className={modal.modalInputGroup}>
                                <input type="checkbox" id="switch" name="mode" onChange={colorMode} />
                                <label htmlFor="switch" className={modal.modalLabel}>다크모드</label>
                            </div>
                            </>
                            }
                            {!chkEditable &&
                            <>
                            <div className={modal.modalInputGroup}>
                                <p className={profile.profileText}>{handleUserStat(profileUser.usiStat)}</p>
                                <div>
                                    <img src=''/>
                                </div>
                            </div>
                            </>
                            }
                        </>
                    </div>
                    <div className={modal.modalButtonGroup}>
                        {
                        chkEditable ?
                        (<button className="btn-p" onClick={handleUpdateButton}>편집</button>)
                        :
                        ('')
                        }
                        <button className="btn-g" onClick={handleCloseModal}>닫기</button>
                    </div>
                </Modal>
    );
}