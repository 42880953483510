import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfo } from "../types/UserInfo.type";
import { PURGE } from "redux-persist";

const initialState: UserInfo = {
    usiNum: 0,
    usiId: '',
    usiEmail: '',
    usiPwd: '',
    usiName: '',
    usiTitle: '',
    usiStat: '',
    usiImgName: '',
    usiImgUuid: '',
    usiImgPath: '',
    usiImgUrl: '',
    credat: '',
    cretim: '',
    lmodat: '',
    lmotim: '',
    active: '',
    unReadCnt: 0
};

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser: (state: UserInfo, action: PayloadAction<UserInfo>) => {
            return { ...state, ...action.payload };
        },
        initUser: () => {
            return initialState;
        }
    },
    extraReducers: (builder) => {
        // 로그아웃 reducer
        builder.addCase(PURGE, () => {
            return initialState;
        });
    }
});

export const { setUser, initUser } = userSlice.actions;
export default userSlice.reducer;