import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";

type ModalType = 'user' | 'channel' | 'editMessage' | 'deleteMessage' | 'profile';

interface ModalState {
    isOpen: boolean;
}
const initialState : {[key in ModalType]: ModalState} = {
    user : { isOpen : false },
    channel : {isOpen : false},
    editMessage : {isOpen : false},
    deleteMessage : {isOpen : false},
    profile : {isOpen : false}
};

const modalSlice = createSlice({
    name: 'modal',
    initialState:initialState,
    reducers:{
        openModal: (state, action:PayloadAction<ModalType>)=>{
            return {
                ...state,
                [action.payload] : {...state[action.payload], isOpen : true}
            }
        },
        closeModal:(state, action:PayloadAction<ModalType>) => {
            return {
                ...state,
                [action.payload] : {...state[action.payload], isOpen : false}
            }
        }
    },
    extraReducers :(builder)=>{
        // 로그아웃 reducer
        builder.addCase(PURGE, ()=>initialState);
    }
});

export const {openModal, closeModal} = modalSlice.actions;
export default modalSlice.reducer;