import { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { Login } from './components/Login';
import { Main } from './components/Main';
import { Workspace } from './components/Workspace';
import { useChatDispatch, useChatSelector } from './store';
import { disconnectClient, initClient, publishMsg } from './service/ClientService';
import { persistor } from './store';
import { MessageInfo } from './types/MessageInfo.type';
import { setMessageList } from './store/messageListSlice';
import { AddWorkspace } from './components/AddWorkspace';
import { Join } from './components/Join';
import Modal from 'react-modal';
import axios from 'axios';
import { setUserList } from './store/userListSlice';
import { setChannelList } from './store/channelListSlice';
import { setWorkspaceList } from './store/workspaceListSlice';
import { EmojiInfo } from './types/EmojiInfo.type';
import { MessageDTO } from './types/MessageDTO.type';
import { setEmojiList } from './store/emojiListSlice';
import { FileInfo } from './types/FileInfo.type';
import { setFileList } from './store/fileListSlice';
import {apiUrl} from './apis/axiosHttp';

//퍼블
import { toast, ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import customToast from './css/customToast.module.scss';
import { UserInfo } from './types/UserInfo.type';
import './css/style.scss';
import './App.css';

Modal.setAppElement('#root'); // 리액트모달 기준요소 설정

function App() {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const user = useChatSelector((state:any) => state.user);
  const userList = useChatSelector((state:any)=>state.userList.list);
  const chatUser = useChatSelector((state:any)=>state.chatUser);
  const workspace = useChatSelector((state:any)=>state.workspace);
  const channel = useChatSelector((state:any)=>state.channel);
  const dispatch = useChatDispatch();
  
  const messageToast = (message:MessageInfo) => {
    const messageUser = userList.find((tmpUser:any) => (
      tmpUser.usiNum === message.msiSenderUsiNum
    ));    
    toast(<div className={customToast.wrap}>
            <span className={customToast.toastUserName}>{messageUser.usiName}</span>
            <span className={customToast.toastMessage}>{message.msiMessage}</span>
          </div>, {
            position : 'bottom-right',
            autoClose : 3000,
            closeOnClick : true,
            closeButton : true
          });
  }

  const configs = [{
    url: `/topic/enter-chat`,
    callback: (data:any)=>{
      
    }
  },{
    url: `/topic/chat/${user.usiNum}`,
    callback : async (data:any) => {
      const messageDTO:MessageDTO = JSON.parse(data.body);
      
      const emoji:EmojiInfo = messageDTO.emoji || {};
      const message:MessageInfo = messageDTO.message || {};
      const fileList:FileInfo[] = messageDTO.fileList || [];
      const updateUser:UserInfo = messageDTO.user || {};
      if(message.msiType==='UPDATE_STAT'){
        // 프로필수정,상태수정 포함
        const tmpUserList = JSON.parse(localStorage.getItem('userList') || '[]');
        const tmpUpdateUser = tmpUserList.find((tmpUser:any) => (tmpUser.usiNum === updateUser.usiNum));
        if(tmpUpdateUser){
          tmpUpdateUser.usiStat = updateUser.usiStat
        }
        dispatch(setUserList(tmpUserList));
        return;
      }

      if(message.msiType==='UPDATE_USER'){
        // 프로필수정,상태수정 포함
        const tmpUserList = JSON.parse(localStorage.getItem('userList') || '[]');
        const tmpUpdateUserList = tmpUserList.map((tmpUser:any) => {
          if(tmpUser.usiNum === updateUser.usiNum){
            return updateUser;
          }
          if(tmpUser.usiNum !== updateUser.usiNum){
            return tmpUser;
          }
        });
        dispatch(setUserList(tmpUpdateUserList));
        return;
      }
      if(message.msiType==='INVITE_USER'){
        // 유저초대 매핑인 경우 메세지 관련 로직 x
        try{
          const tmpWorkspaceList = JSON.parse(localStorage.getItem('workspaceList') || '[]');
          const req = {
            woiNum : message.woiNum
          }
          const res = await axios.post(`${apiUrl}/workspace-info`, req, {
            headers : {
              "Content-Type" : 'application/json;charset=UTF-8',
              Authorization : `Bearer ${token}`
            }
          });

          if(res.data){
            const newWorkspace = res.data;
            tmpWorkspaceList.push(newWorkspace);
            dispatch(setWorkspaceList(tmpWorkspaceList));
          }
        }catch(e){
            console.error(e);
        }
        return; // 유저초대 매핑인 경우 메세지 관련 로직 x
      }
      const messageUpdate = async () => {
        if(chatUser.usiNum && !channel.chiNum){
        try{
          await axios.put(`${apiUrl}/update-messages`,{
          msiSenderUsiNum : message.msiSenderUsiNum,
          msiReceiveUsiNum : user.usiNum
        },{
          headers : {
            Authorization : `Bearer ${token}`
          }
        });
        }catch(e){
          console.error(e);
        }
      }
      if(!chatUser.usiNum && channel.chiNum){
        try{
          await axios.put(`${apiUrl}/update-channel-messages`,{
          msiReceiveUsiNum : 0,
          chiNum : channel.chiNum
        },{
          headers : {
            Authorization : `Bearer ${token}`
          }
        });
        }catch(e){
          console.error(e);
        }
      }
    }
      if(((message.msiSenderUsiNum === chatUser.usiNum && message.msiReceiveUsiNum === user.usiNum) || 
      ( message.msiReceiveUsiNum === chatUser.usiNum && message.msiSenderUsiNum === user.usiNum))
      ){
        // 내가 받는 메세지거나, 내가 보내는 메세지거나(user)
        const messageList = JSON.parse(localStorage.getItem('messageList') || '{}');
        const emojiList = JSON.parse(localStorage.getItem('emojiList') || '{}');
        if(emoji.emiType === 'NEW'){
          // 이모지 추가
          emojiList.push(emoji);
          dispatch(setEmojiList(emojiList));
          return;
        }
        if(emoji.emiType === 'DEL'){
          // 이모지 삭제
          const deleteEmoji = emojiList.find((tmpEmoji:any)=>tmpEmoji.emiNum === emoji.emiNum);
          if(deleteEmoji){
            deleteEmoji.emiType = emoji.emiType;
            deleteEmoji.active = emoji.active;
          }
          dispatch(setEmojiList(emojiList));
          return;
        }
        if(emoji.emiType === 'UDT'){
          // 이모지 수정
          const updateEmoji = emojiList.find((tmpEmoji:any)=>tmpEmoji.emiNum === emoji.emiNum);
          if(updateEmoji){
            updateEmoji.emiType = emoji.emiType;
            updateEmoji.emiCount = emoji.emiCount;
          }
          dispatch(setEmojiList(emojiList));
          return;
        }
        if(message.msiDeleteTime){
          // 메세지 삭제
          const deleteMsg = messageList.list.find((msg:any)=>msg.msiNum===message.msiNum);
          if(deleteMsg){
            deleteMsg.msiDeleteTime = message.msiDeleteTime;
            deleteMsg.active = message.active;
          }
          dispatch(setMessageList(messageList));
          return;
        }

        if(message.msiEditTime && !message.msiDeleteTime){
          // 메세지 수정
          const editedMsg = messageList.list.find((msg:any)=>msg.msiNum===message.msiNum);
          if(editedMsg){
            editedMsg.msiMessage = message.msiMessage;
            editedMsg.msiEditTime = message.msiEditTime;
          }
          dispatch(setMessageList(messageList));
          return;
        }
        if(!message.msiEditTime && !message.msiDeleteTime){
          // 메세지 인풋
          if(fileList.length){
            const tmpFileList = JSON.parse(localStorage.getItem('fileList') || '[]');
            for(const file of fileList){
              tmpFileList.push(file);
            }
            dispatch(setFileList(tmpFileList));
          }
          messageList.list.push(message);
          dispatch(setMessageList(messageList));
          messageUpdate();
          return;
        }
      }else{
        const tmpUserList = JSON.parse(localStorage.getItem('userList') || '[]');
        const tmpChannelList = JSON.parse(localStorage.getItem('channelList') || '[]');
        if(!message.chiNum){
        for(const tmpUser of tmpUserList){
          if((message.msiReceiveUsiNum === user.usiNum) && (message.msiSenderUsiNum === tmpUser.usiNum)){
            ++tmpUser.unReadCnt
          }
        }
        // 여기서 메세지 알림 토스트기능이 추가되어야함
        messageToast(message);
        dispatch(setUserList(tmpUserList));
        }
        if(message.chiNum){
          const newChannelChk = tmpChannelList.every((tmpChannel:any)=>tmpChannel.chiNum !== message.chiNum);
          if(newChannelChk){
            // 채널 생성자가 아니고, 새로운 채널을 생성한후 받은 메세지인 경우
            const req = {
              chiNum : message.chiNum
            }
            try{
              const res = await axios.post(`${apiUrl}/channel-info`, req,{
                headers : {
                  "Content-Type" : 'application/json;charset=UTF-8',
                  Authorization : `Bearer ${token}`
                }
              });
              if(res.data){
                const newChannel = res.data;
                tmpChannelList.push(newChannel);
              }
            }catch(e){
              console.error(e)
            }
          }
          
          for(const tmpChannel of tmpChannelList){
            if((message.chiNum === tmpChannel.chiNum) && (message.msiSenderUsiNum !== user.usiNum)){
              ++tmpChannel.unReadCnt
            }
          }
          // 여기서 메세지 알림 토스트기능이 추가되어야함
          messageToast(message);
          dispatch(setChannelList(tmpChannelList));
        }    
      }
    }
  }]

  useEffect(()=>{
    disconnectClient();
    if(!user.usiNum){
      persistor.purge();
      localStorage.clear();
      navigate('/');
      return;
    }
    const connectClient = async () => {
      try{
        await initClient(configs);
        if(user.usiNum){
          const destination = `/publish/chat/${user.usiNum}`
        publishMsg(destination, {
            woiNum : workspace.woiNum,
            msiType : 'UPDATE_STAT'
        }, {}, [], {
            usiNum : user.usiNum,
            usiStat : 'ONLINE'
        });
        }
      }catch(e){
        console.error(e);
      }
    }
    setTimeout(connectClient, 100);
    // user를 제거한 이유는 사용자 프로필 변경시 재연결되는 이슈를 막고자 하였다.
    // 해당 프로세스는 workspace가 변경할때로 대체가능하다고 판단하였다.
    // useEffect에 chatUser, channel 제거함...재연결 이슈
  },[workspace, chatUser, channel]);

  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Login/>}></Route>
        <Route path='/main' element={<Main/>}></Route>
        <Route path='/workspace' element={<Workspace/>}></Route>
        <Route path='/add-workspace' element={<AddWorkspace/>}></Route>
        <Route path='/join' element={<Join/>}></Route>
      </Routes>
      <ToastContainer position='bottom-right' limit={1} closeButton={true} hideProgressBar/>
    </div>
  );
}

export default App;
