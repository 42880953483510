import {ReactComponent as LogoImg} from '../assets/logo.svg';
import {ReactComponent as WorkspaceImg} from '../assets/ico_workspace.svg';
import {ReactComponent as LogoutImg} from '../assets/ico_logout.svg';
import { Link } from 'react-router-dom';
import header from '../css/header.module.scss';
import { persistor } from '../store';
import { useChatDispatch, useChatSelector } from '../store';
import { publishMsg } from '../service/ClientService';
import { initWorkspace } from '../store/workspaceSlice';
import { initChatUser } from '../store/chatUserSlice';
import { initMessageList } from '../store/messageListSlice';
import axios from 'axios';
import { apiUrl } from '../apis/axiosHttp';
export const Header = ()=>{

    const workspace = useChatSelector((state:any)=>state.workspace);
    const user = useChatSelector((state:any)=>state.user);
    const token = localStorage.getItem('token') || '';
    const dispatch = useChatDispatch();
    const logoutProcess = async () => {
        // user 상태 업데이트 후 로그아웃
        const destination = `/publish/chat/${user.usiNum}`
        publishMsg(destination,{
            woiNum : workspace.woiNum,
            msiType : 'UPDATE_STAT'
        },{},[],{
            usiNum : user.usiNum,
            usiStat : 'OFFLINE'
        });
        try{
            const res = await axios.post(`${apiUrl}/logout`, {}, {
                headers : {
                    "Content-Type" : 'application/json;charset=UTF-8',
                    'Authorization' : `Bearer ${token}`
                }
            });
            persistor.purge();
        }catch(e){
            console.error(e);
        }
        
    }

    const goWorkspace = () => {
        dispatch(initWorkspace());
        dispatch(initChatUser());
        dispatch(initMessageList());
        
    }

    return (
        <div className={header.wrap}>
            <LogoImg className={header.logo}/>
            <div className={header.links}>
            <Link to="/workspace" className={header.link} onClick={goWorkspace} title='워크스페이스'>
                <WorkspaceImg/>
            </Link>
            <Link to="/" className={header.link} onClick={logoutProcess} title='로그아웃'>
                <LogoutImg/>
            </Link>
            </div>
        </div>
    );
}