import Modal from 'react-modal';
import { useChatDispatch, useChatSelector } from '../store';
import { closeModal } from '../store/modalSlice';
import modal from '../css/modal.module.scss';
import { publishMsg } from '../service/ClientService';
import { initMessage } from '../store/messageSlice';
import { MessageInfo } from '../types/MessageInfo.type';
import { getDateTime, handleTime } from '../service/DateService';
import messageStyle from '../css/messageStyle.module.scss'

export const DeleteMessageModal = ()=>{
    const dispatch = useChatDispatch();
    const isOpen = useChatSelector((state:any)=>state.modal.deleteMessage.isOpen);
    const chatUser = useChatSelector((state:any)=>state.chatUser);
    const channel = useChatSelector((state:any)=>state.channel);
    const user = useChatSelector((state:any)=>state.user);
    const memberList = useChatSelector((state:any)=>state.memberList.list);
    const message = useChatSelector((state:any)=>state.message);

    const handleCloseModal = () =>{
        dispatch(initMessage());
        dispatch(closeModal('deleteMessage'));
    }

    const deleteMessage = async (message:MessageInfo) => {

        if(message.msiNum){
            const dateTime = getDateTime();
            const req = {
                msiNum : message.msiNum,
                msiSenderUsiNum : message.msiSenderUsiNum,
                msiReceiveUsiNum : message.msiReceiveUsiNum,
                msiType : message.msiType,
                chiNum : message.chiNum,
                msiDeleteTime : dateTime,
                active : '0'
                }

                const destination = `/publish/chat/${chatUser.usiNum ? chatUser.usiNum : channel.chiNum}`;
                
                publishMsg(destination, req, {}, [], {});
        }
        dispatch(initMessage());
        dispatch(closeModal('deleteMessage'));
    }

    return (
        <Modal isOpen={isOpen} onRequestClose={handleCloseModal} className={modal.modalContent} overlayClassName={modal.modalOverlay}>
                    <div className={modal.modalTop} >
                        <h2 className={modal.modalTitle}>메세지 삭제</h2>
                    </div>
                    <div className={modal.modalBody}>
                        <div className={modal.deleteLabel}>
                            <label htmlFor="">이 메세지를 삭제하시겠습니까?</label>
                        </div>
                    <div className={messageStyle.message}>
                    <div className={messageStyle.content}>
                    <div className={messageStyle.author}>
                        {
                        
                            (channel.chiNum && message.msiSenderUsiNum)
                    
                            ?
                            (
                            memberList.find((member:any) => member?.usiNum === message.msiSenderUsiNum)?.usiName
                            )
                            :
                            (
                            message.msiSenderUsiNum === user.usiNum ? user.usiName : chatUser.usiName
                            )
                        }
                    </div>
                    <span className={messageStyle.timeStamp}>{handleTime(message.msiSentTime || '')}</span>
                    </div>
                    <div className={messageStyle.text}>{message.msiMessage}{message.msiEditTime ? <span className={messageStyle.edited}>(편집됨)</span> : ''}</div>
                </div>
                    </div>
                    <div className={modal.modalButtonGroup}>
                        <button className="btn-a" onClick={()=>{deleteMessage(message)}}>삭제</button>
                        <button className="btn-p" onClick={handleCloseModal}>닫기</button>
                    </div>
                </Modal>
    );
}