import { createSlice } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";
import { MessageInfo } from "../types/MessageInfo.type";

const initialState:MessageInfo = {
    msiNum : 0,
    msiMessage : '',
    msiSenderUsiNum : '',
    msiReceiveUsiNum : '',
    msiSentTime : '',
    msiReceiveTime : '',
    msiEditTime: '',
    msiDeleteTime: '',
    msiType : '',
    chiNum : 0,
    credat : '',
    cretim : '',
    lmodat : '',
    lmotim : '',
    active : '',
    woiNum : 0
}
const messageSlice = createSlice({
    name : 'message',
    initialState: initialState,
    reducers:{
        setMessage : (state:MessageInfo, action:any)=>{
            state.msiNum = action.payload.msiNum;
            state.msiMessage = action.payload.msiMessage;
            state.msiSenderUsiNum = action.payload.msiSenderUsiNum;
            state.msiReceiveUsiNum = action.payload.msiReceiveUsiNum;
            state.msiSentTime = action.payload.msiSentTime;
            state.msiReceiveTime = action.payload.msiReceiveTime;
            state.msiEditTime = action.payload.msiEditTime;
            state.msiDeleteTime = action.payload.msiDeleteTime;
            state.msiType = action.payload.msiType;
            state.chiNum = action.payload.chiNum;
            state.credat = action.payload.credat;
            state.lmodat = action.payload.lmodat;
            state.lmotim = action.payload.lmotim;
            state.active = action.payload.active;
            state.woiNum = action.payload.woiNum;
        },
        initMessage:(state:MessageInfo)=>{
            return initialState;
        }
    },
    extraReducers : (builder) => {
        builder.addCase(PURGE, ()=> initialState)
    }
});

export const {setMessage, initMessage} = messageSlice.actions;
export default messageSlice.reducer;
